angular.module("prisma")
 .controller("EditStoresCtrl", function ($scope, $stateParams, $translate, $state, $uibModal, spaceService, adminService, companyJson) {

     //Variables Globales
     var self = this;

     //TRADUCCIONES JS
     var saveSwalTitle = "";
     var storeSaved = "";
     var errorWhenSavingTheStore = "";
     var validationNameStore = "";
     var validationTypeStore = "";

     translate = function () {

         //Llamar por cada traduccion
         $translate("SaveSwalTitle")
            .then(function (translation) {
                saveSwalTitle = translation;
            });

         $translate("StoreSaved")
            .then(function (translation) {
                storeSaved = translation;
            });
         $translate("ErrorWhenSavingTheStore")
            .then(function (translation) {
                errorWhenSavingTheStore = translation;
            });
         $translate("ValidationNameStore")
            .then(function (translation) {
                validationNameStore = translation;
            });
         $translate("ValidationTypeStore")
            .then(function (translation) {
                validationTypeStore = translation;
            });

         $translate("Store")
           .then(function (translation) {
               var storeType1 = translation;

               $translate("StoreAndCD")
           .then(function (translation) {
               var storeType3 = translation;

               self.storesTypes = [
                    {
                        value: 1,
                        type: storeType1
                    },
                    {
                        value: 2,
                        type: "Centro de distribucion"
                    },
                    {
                        value: 3,
                        type: storeType3
                    }
               ]
           });
           });



     }
     translate();

     self.stores = [];

     self.setupData = companyJson;

     self.store = { id: $stateParams.storeId, name: '', code: '', isExcluded: false };
     self.validationMessage = ""
     self.layouts = [];
     self.clusters = [];
     self.priceLists = [];
     self.vendors = [];
     self.distributionCenters = [];
     self.operationsTypes = [
         {
             value: 1,
             type: "COCO"
         },
         {
             value: 2,
             type: "COFO"
         },
         {
             value: 3,
             type: "FOFO"
         },
         {
             value: 4,
             type: "FOCO"
         }
     ];
     self.brands = [];

     //self.storesTypes = [];

     self.setupData = companyJson;

     //Funciones

     self.addNew = function () {
         self.store.id = 0;
     }

     self.editStore = function (store) {
         self.store = store;
     }

     self.toggleNewStore = function () {
         self.store.isExcluded = !self.store.isExcluded;

         if (self.store.isExcludedLiteral == "Activa") {
             self.store.isExcludedLiteral = "Deshabilitada";
         }
         else {
             self.store.isExcludedLiteral = "Activa";
         }

     }

     self.save = function () {

         var validation = validateBeforeSave();
         if (validation) {
             swal("Error", validation, "error");
             return;
         }

         adminService.stores.doesStoreCodeExists(self.store.code, self.store.id).then(function (exists) {
             existingCodeText = "";
             if (exists && !self.store.isExcluded)
                 existingCodeText = "Este codigo ya existe para otra tienda, si continua se deshabilitara la tienda que posee el codigo ingresado. ";

             swal({
                 title: saveSwalTitle,
                 text: existingCodeText + "Si realizo cambios en las zonas de precios, estos se veran reflejados mañana. Por favor vuelva a revisar las sugerencias para estas zonas.",
                 type: "warning",
                 showCancelButton: true,
                 confirmButtonColor: "#1AB394",
                 confirmButtonText: "Continuar",
                 cancelButtonText: "Cancelar",
                 showLoaderOnConfirm: true,
                 closeOnConfirm: false,
                 closeOnCancel: true
             },
            function (isConfirm) {

                if (!isConfirm) return;

                var data = self.store;

                data.priceLists = [];
                data.clusters = [];

                if (companyJson.Navigation.Prices && data.storeType == 1) {
                    angular.forEach(self.categories, function (category) {
                        data.priceLists.push({
                            category3Id: category.id,
                            priceListId: category.priceListId,
                            storeId: parseInt(self.store.id)
                        })
                    })
                }

                if (companyJson.Navigation.Assortment && data.storeType == 1) {
                    angular.forEach(self.categories, function (category) {
                        data.clusters.push({
                            category3Id: category.id,
                            clusterId: category.clusterId,
                            storeId: parseInt(self.store.id)
                        })
                    })
                }

                console.log('data', data);

                adminService.stores.saveStore(data).then(function () {
                    swal("Confirmado", storeSaved, "success");
                    $state.go('admin.stores');
                }),
                function () {
                    swal("Error", errorWhenSavingTheStore, "error");
                };
            });
         });
     }

     self.cancel = function () {
         $state.go('admin.stores');
     }

     self.changeSameStore = function (value) {
         self.store.isSameStore = value;

     }

     self.toggleSamePriceList = function (value) {
         self.samePriceListForEveryCategory = value;
         if (value && self.store.priceListId) {
             angular.forEach(self.categories, function (category) {
                 category.priceListId = self.store.priceListId;
             });
         }
     }

     self.toggleSameCluster = function (value) {
         self.sameClusterForEveryCategory = value;
         if (value && self.store.clusterId) {
             angular.forEach(self.categories, function (category) {
                 category.clusterId = self.store.clusterId;
             });
         }
     }

     self.choosePriceListsForCategories = function () {

         var modalInstance = $uibModal.open({
             animation: true,
             component: 'choosePriceListsForCategories',
             windowClass: 'app-modal-window',
             resolve: {
                 params: function () {
                     return {
                         categories: angular.copy(self.categories),
                         priceLists: self.priceLists
                     };
                 }
             }
         });
         //console.log('modalInstance', modalInstance);
         //return;
         modalInstance.result.then(function (result) {
             console.log('result', result)
             self.categories = result;
         });

     }

     self.chooseClustersForCategories = function () {

         var modalInstance = $uibModal.open({
             animation: true,
             component: 'chooseClustersForCategories',
             windowClass: 'app-modal-window',
             resolve: {
                 params: function () {
                     return {
                         categories: angular.copy(self.categories),
                         clusters: self.clusters
                     };
                 }
             }
         });

         modalInstance.result.then(function (result) {
             console.log('result', result)
             self.categories = result;
         });

     }

     self.priceListChange = function () {
         console.log('new price list', self.store.priceListId);
         angular.forEach(self.categories, function (category) {
             category.priceListId = self.store.priceListId;
         });
     }

     self.clusterChange = function () {
         console.log('new cluster', self.store.clusterId);
         angular.forEach(self.categories, function (category) {
             category.clusterId = self.store.clusterId;
         });
     }

     function init() {
         //console.log('$stateParams', $stateParams); 
         self.samePriceListForEveryCategory = true;
         self.sameClusterForEveryCategory = true;

         adminService.categories.getCategoryGroups().then(function (categories) {
             console.log('categories', categories);
             self.categories = categories;

             //Primero tengo que traer las categories, por eso lo nesteo aca
             if ($stateParams.storeId > 0) {
                 adminService.stores.getStore($stateParams.storeId).then(function (store) {
                     console.log('store', store);
                     if (store.openedDate)
                         store.openedDate = new Date(store.openedDate);

                     var last = 0;
                     angular.forEach(store.clusters, function (cluster) {
                         if (cluster.clusterId != last && last != 0) {
                             self.sameClusterForEveryCategory = false;
                         }
                         last = cluster.clusterId;

                         angular.forEach(self.categories, function (category) {
                             if (category.id == cluster.category3Id)
                                 category.clusterId = cluster.clusterId;
                         })
                     })


                     last = 0;
                     angular.forEach(store.priceLists, function (priceList) {
                         if (priceList.priceListId != last && last != 0) {
                             self.samePriceListForEveryCategory = false;
                         }
                         last = priceList.priceListId;

                         angular.forEach(self.categories, function (category) {
                             if (category.id == priceList.category3Id)
                                 category.priceListId = priceList.priceListId;
                         })
                     })

                     if (self.samePriceListForEveryCategory && store.priceLists.length > 0)
                         store.priceListId = store.priceLists[0].priceListId;

                     if (self.sameClusterForEveryCategory && store.clusters.length > 0)
                         store.clusterId = store.clusters[0].clusterId;

                     self.store = store;
                 });
             }

         })

         adminService.vendors.getVendorsCombo().then(function (vendors) {
             console.log('vendors', vendors);
             self.vendors = vendors;
         });

         adminService.stores.getDistributionCentersCombo().then(function (dc) {
             console.log('dc', dc);
             self.distributionCenters = dc;
         })

         adminService.clusters.getClusters().then(function (clusters) {
             console.log('clusters', clusters);
             self.clusters = clusters;
         });

         adminService.priceLists.getPriceLists().then(function (priceLists) {
             console.log('pricelists', priceLists);
             self.priceLists = priceLists;
         });

         adminService.brands.getBrandsCombo(true).then(function (brands) {
             console.log('brands', brands);
             self.brands = brands;

             if (self.brands.length == 1) {
                 self.store.brandId = brands[0].id;
             }
         });

         //TODO: Mergear con el branch de espacios.
         //spaceService.getLayouts().then(function (layouts) {
         //    self.layouts = layouts;
         //});


     }

     function validateBeforeSave() {

         if (!self.store.name) {
             return validationNameStore;
         }
         if (!self.store.openedDate) {
             return "Debe elegir una fecha de apertura"
         }

         if (companyJson.Navigation.Prices && self.store.storeType == 1) {

             var valid = true;
             angular.forEach(self.categories, function (category) {
                 if (!category.priceListId)
                     valid = false;
             })
             if (!valid) {
                 return "Seleccione zona de precios para todas las categorias"
             }
         }

         if (companyJson.Navigation.Assortment && self.store.storeType == 1) {

             //var valid = true;
             //angular.forEach(self.categories, function (category) {
             //    if (!category.clusterId)
             //        valid = false;
             //})
             //if (!valid) {
             //    return "Seleccione cluster para todas las categorias"
             //}
         }
         if (!self.store.operationType) {
             return "Debe elegir un tipo de operacion";
         }

         return null;
     }

     self.storeTypeChange = function () {
         self.store.distributionCenterId = null;
     }

     self.isValid = function () {
         let validations = [];
         if (!companyJson) return false;

         if (!self.store.name) {
             validations.push(validationNameStore);
         }
         if (!self.store.code) {
             validations.push("Debe ingresar un codigo para la tienda");
         }
         if (!self.store.operationType) {
             validations.push("Debe elegir un tipo de operacion");
         }
         if (!self.store.openedDate) {
             validations.push("Debe elegir una fecha de apertura");
         }
         if (!self.store.storeType) {
             validations.push(validationTypeStore);
         }

         //Si es obligatorio elegir centro de distribucion, y el tipo es TIENDA... 
         //TODO: revisar que pasa con las tiendas que a su vez son centros de distribucion
         if (companyJson.EditStores && companyJson.EditStores.DistributionCenterMandatory && !self.store.distributionCenterId && self.store.storeType == 1) {
             validations.push("Debe elegir un centro de distribucion");
         }

         if (companyJson.Navigation.Prices) {
             if (self.store.storeType == 1) {
                 var valid = true;
                 angular.forEach(self.categories, function (category) {
                     if (!category.priceListId)
                         valid = false;
                 })
                 if (!valid) {
                     validations.push("Seleccione zona de precios para todas las categorias");
                 }
             }
         }

         if (companyJson.Navigation.Assortment) {
             //var valid = true;
             //angular.forEach(self.categories, function (category) {
             //    if (!category.clusterId)
             //        valid = false;
             //})
             //if (!valid) {
             //    validations.push("Seleccione cluster para todas las categorias");
             //}
         }



         //if(setupData.DistributionCenterMandatory && )
         self.validationMessage = validations.join(', ');

         if (validations.length > 0)
             return false;

         return true;
     }

     self.mustHidePriceChanges = function () {
         if (!companyJson) return false;

         if (!companyJson.EditStores) return false;

         if (companyJson.EditStores.HidePriceChanges)
             return true;
     };

     self.mustHideLiquidations = function () {
         if (!companyJson) return false;

         if (!companyJson.EditStores) return false;

         if (companyJson.EditStores.HideActiveLiquidations)
             return true;
     };

     //button Collapsed
     $scope.isNavCollapsed = true;
     $scope.isCollapsed = false;
     $scope.isCollapsedHorizontal = false;

     $scope.isGeneralInformationCollapsed = false;
     $scope.isUbicationCollapsed = false;
     $scope.isOthersCollapsed = false;

     init();
 });




