angular.module("prisma")
 .controller("myTasksCtrl", function ($rootScope, $scope, $state, promotionsService, authService) {
     var self = this;

     self.isSuperUser = authService.hasPermission('promotion_superUser');
     self.roles = authService.authentication.roles;
     self.userAreas = promotionsService.userAreas;

     self.currDate = Date.now();
     self.tasks = [];
     self.allResponsables = [];

     self.search = '';
     self.order = ["plannedStartDate", "plannedEndDate"];

     self.filter = {
         taskName: "",
         plannedStatusDesc: "ReadyBlockedByDependency",
         effectiveStatusDesc: "",
         textResponsable: "Todos"
     }

     self.filterTask = function (taskName) {
         self.filter.taskName = taskName;
     }

     self.setFilterPlannedStatus = function (status) {
         self.filter.plannedStatusDesc = status;
     }

     self.setFilterEffectiveStatus = function (status) {
         self.filter.effectiveStatusDesc = status;
     }

     self.setFilterResponsable = function (resp) {
         self.filter.textResponsable = resp;
     }

     self.isLoading = true;

     self.navigateToAction = function (task) {

         self.setFiltersInLocalStorage();

         if (!self.isSuperUser) { //Lo bloqueo si no es SuperUser
             if (task.plannedStatusDesc === 'Ready') {
                 if (task.effectiveStatusDesc === 'Completed') {
                     swal("La tarea ya fue publicada", "No se puede trabajar una tarea que ya fue publicada.", "error");
                 }
                 else {
                     //comenzar tarea 
                     if (task.effectiveStatusDesc == 'Pending') {
                         promotionsService.tasks.startTask(task.taskId)
                                .then(function (startedTask) {
                                    $state.go(task.navigateToState, { promotionId: task.promotionId, taskId: task.taskId });

                                });
                     }
                     else { //(si ya comenzo se fija que sea el responsable o superUser)
                         promotionsService.tasks.canStartTask(task.taskId)
                                .then(function () {
                                    $state.go(task.navigateToState, { promotionId: task.promotionId, taskId: task.taskId });
                                });
                     }

                 }

             }
             else if (task.plannedStatusDesc === 'BlockedByDate') {
                 swal("La tarea esta bloqueada", "La tarea no se encuentra en el rango de fechas definido para poder iniciarse.", "error");
             }
             else if (task.plannedStatusDesc === 'BlockedByDependency') {
                 swal("La tarea esta bloqueada", "No puede iniciar esta tarea hasta que su predecesora ('" + task.previousTaskName + "') haya finalizado.", "error");
             }
         }
         else {
             //Voy a la tarea
             $state.go(task.navigateToState, { promotionId: task.promotionId, taskId: task.taskId });
         }
     }

     self.myFilter = function (task) {

            return (//que pase el filtrado
                ((task.userArea.toLowerCase().indexOf(self.filter.textResponsable.toLocaleLowerCase()) > -1 || self.filter.textResponsable == "Todos"))
                && ((self.filter.taskName.toLowerCase().indexOf(task.taskName.toLocaleLowerCase()) > -1 || self.filter.taskName == ""))
                && ((self.filter.plannedStatusDesc.toLowerCase().indexOf(task.plannedStatusDesc.toLocaleLowerCase()) > -1 || self.filter.plannedStatusDesc == ""))
                && ((self.filter.effectiveStatusDesc.toLowerCase().indexOf(task.effectiveStatusDesc.toLocaleLowerCase()) > -1 || self.filter.effectiveStatusDesc == ""))
                )
                &&
                (//que pase la busqueda
                    self.search == ''
                    || (task.taskName && task.taskName.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.promotionName && task.promotionName.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.previousTaskName && task.previousTaskName.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.plannedStatusDesc && task.plannedStatusDesc.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.effectiveStatusDesc && task.effectiveStatusDesc.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.effectiveStartedDate && task.effectiveStartedDate.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.effectiveEndedDate && task.effectiveEndedDate.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                    || (task.promotionId && task.promotionId.toString().toLowerCase().indexOf(self.search.toLowerCase()) > -1)

                )
                &&             
                (//que pase si esta en fecha
                    self.filterDate == false
                    || (new Date(task.plannedStartDate) >= self.fromDate && new Date(task.plannedStartDate) <= self.toDate)
                    || (new Date(task.plannedEndDate) >= self.fromDate && new Date(task.plannedEndDate) <= self.toDate)
                );
         
     };

     function checkTasksStartOrEnd()
     {
         promotionsService.tasks.checkTasksStartOrEnd()
         .then(function () {
             //No hago nada, se llama cada vez que se entra en esta pantalla para enviar los mails de que hoy empieza una promo o que hoy finaliza si no está terminada.
         });
     }

     self.setFiltersInLocalStorage = function () {
         var filters = {
             'tarea': self.filter.taskName,
             'responsable': self.filter.textResponsable,
             'bloqueo': self.filter.plannedStatusDesc,
             'estado': self.filter.effectiveStatusDesc,
             'buscador': self.search
         };
         localStorage.setItem('filtros', JSON.stringify(filters));
     }

     function getFilters() {
         if (localStorage.getItem('filtros') != null) {
             var filtersAux = localStorage.getItem('filtros');
             var filters = JSON.parse(filtersAux);

             self.filter.taskName = filters.tarea;
             self.filter.textResponsable = filters.responsable
             self.filter.plannedStatusDesc = filters.bloqueo
             self.filter.effectiveStatusDesc = filters.estado;
             self.search = filters.buscador;
         }
         localStorage.removeItem("filtros");
     }

     //Filtrar por fechas

     self.filterDate = false;
     self.fromDate = null;
     self.toDate = null;

     self.filterByDate = function () {
         self.filterDate = !self.filterDate;
     }

     self.removeFilter = function () {
         self.filterDate = false;
         self.fromDate = null;
         self.toDate = null;
     }

     self.applyFilter = function () {
         if (self.fromDate == null || self.toDate == null) {
             swal("No se aplicaron los filtros", "Debe completar la fecha desde y la fecha hasta.", "warning");
         }
         else if (self.fromDate > self.toDate) {
             swal("Error", "La fecha desde debe ser anterior a la fecha hasta.", "error");
         }
         else {
             self.plannedStartDate = fromDate;
         }
     }

     function init() {
         promotionsService.tasks.getUserTasks().then(function (tasks) {
             self.tasks = tasks;
             let arr = tasks.map(function (task) {
                 return { value: task.taskName };
             });
             self.taskNames = $rootScope.removeDuplicates(arr, 'value');
             self.plannedStatusList = [
                'Ready',
                'BlockedByDate',
                'BlockedByDependency',
                'Expired'
             ];
             self.effectiveStatusList = [
                'Pending',
                'InProgress',
                'Completed'
             ];
             angular.forEach(tasks, function (t) {
                 t.plannedEndDate = new Date(moment(t.plannedEndDate).subtract(1, 'days'));//evito confuciones de fin de tarea

                 var exists = _.some(self.allResponsables, function (r) {
                     return r == t.userArea;
                 });

                 if (!exists) {
                     self.allResponsables.push(t.userArea);

                     var roles = promotionsService.getUserRolesByArea(t.userArea);

                     if (self.filter.textResponsable == "Todos" && roles.indexOf(self.roles[0]) > -1) //Set Filter to Current User Role
                         self.filter.textResponsable = t.userArea;
                 }
             });

             self.isLoading = false;
         });

         self.setFilterResponsable(promotionsService.getUserAreasByRole(self.roles[0])[0]);

         checkTasksStartOrEnd();

         getFilters();
     }


     init();
 });