(function () {
    'use strict';

    angular
        .module('prisma')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider, $urlRouterProvider, companyJson) {

        $stateProvider
            .state("login", {
                url: "/login",
                templateUrl: "app/login/login.html",
                controller: 'LoginCtrl',
                controllerAs: "ct"
            })
            .state("home", {
                abstract: true,
                url: "/home",
                templateUrl: "app/components/common/content.html"
            })
            .state("home.dashboardV1", { 
                url: "/dashboardV1",
                templateUrl: "app/home/dashboard/dashboard.html",
                data: { pageTitle: "Smart Dashboard" },
                controller: "DashboardCtrl",
                controllerAs: "ct"
            })
            .state("home.dashboard", { //Dashbard de Oils. Harcoded
                url: "/dashboard",
                templateUrl: companyJson.Navigation.UseOilDashboard ? "app/home/dashboardOil/dashboardOil.html" : "app/home/dashboard/dashboard.html",
                data: { pageTitle: "Smart Dashboard" },
                controller: companyJson.Navigation.UseOilDashboard ? "DashboardOilCtrl" : "DashboardCtrl",
                controllerAs: "ct"
            })
            .state("home.marketBoard", {
                url: "/marketBoard",
                templateUrl: "app/home/marketBoard/marketBoard.html",
                data: {
                    pageTitle: "Market Board" },
                controller: "MarketBoardCtrl",
                controllerAs: "ct"
            })
            .state("home.itemDashboard", {
                url: "/itemDashboard/:itemId/:storeId/:clusterId/:priceListId",
                templateUrl: "app/home/itemDashboard/itemDashboard.html",
                data: { pageTitle: "Item Dashboard" },
                controller: "ItemDashboardCtrl",
                controllerAs: "ct"
            })
            .state("home.maps", {
                url: "/maps",
                templateUrl: "app/home/maps/maps.html",
                data: { pageTitle: "Maps" },
                controller: "MapsCtrl",
                controllerAs: "ct"
            })
            .state("home.goals", {
                url: "/goals/:type",
                templateUrl: "app/home/goals/goals.html",
                data: { pageTitle: "Goals" },
                controller: "GoalsCtrl",
                controllerAs: "ct"
            })
            .state("home.mapv2", {
                url: "/mapv2",
                templateUrl: "app/home/mapv2/mapv2.html",
                data: { pageTitle: "Mapv2" },
                controller: "MapV2Ctrl",
                controllerAs: "ct"
            })
            .state("home.heatMap", {
                url: "/heatMap",
                templateUrl: "app/home/heatMap/heatMap.html",
                data: { pageTitle: "Mapa de Calor" },
                controller: "HeatMapCtrl",
                controllerAs: "ct"
            })
            .state("home.heatMapMargin", {
                url: "/heatMapMargin",
                templateUrl: "app/home/heatMap/heatMapMargin.html",
                data: { pageTitle: "Mapa de Calor" },
                controller: "HeatMapMarginCtrl",
                controllerAs: "ct",
                params: {
                    'categoryId': 0
                }
            })
            .state("home.deviations", {
                url: "/deviations",
                templateUrl: "app/home/deviations/deviations.html",
                data: { pageTitle: "Analysis of deviations" },
                controller: "DeviationsCtrl",
                controllerAs: "ct"
            })
            .state("home.benchmarks", {
                url: "/benchmarks",
                templateUrl: "app/home/benchmarks/benchmarks.html",
                data: { pageTitle: "Benchmarks" },
                controller: "BenchmarksCtrl",
                controllerAs: "ct"
            })
            .state("home.weather", {
                url: "/weather",
                templateUrl: "app/home/weather/weather.html",
                data: { pageTitle: "Analysis of weather" },
                controller: "WeatherCtrl",
                controllerAs: "ct"
            })
            .state("home.bcgmatrix", {
                url: "/matrizbcg",
                templateUrl: "app/home/bcgmatrix/bcgmatrix.html",
                data: { pageTitle: "Matriz BCG" },
                controller: "BcgMatrixCtrl",
                controllerAs: "ct"
            })
            .state("home.pivot", {
                url: "/pivot",
                templateUrl: "app/home/pivot/pivot.html",
                data: { pageTitle: "Pivot" },
                controller: "PivotCtrl",
                controllerAs: "ct"
            })
            .state("home.notifications", {
                url: "/notifications",
                templateUrl: "app/home/notifications/notifications.html",
                data: { pageTitle: "Notifications" },
                controller: "NotificationsCtrl",
                controllerAs: "ct"
            })
            .state("home.categoryTreeMap", {
                url: "/categoryTreeMap",
                templateUrl: "app/home/categoryTreeMap/categoryTreeMap.html",
                data: { pageTitle: "CategoryTreeMap" },
                controller: "CategoryTreeMapCtrl",
                controllerAs: "ct"
            })
            .state("home.categoryDst", {
                url: "/category-data-story-telling/:type",
                templateUrl: "app/home/dst/dst.html",
                data: { pageTitle: "DST - Categorias" },
                controller: "DstCtrl",
                controllerAs: "ct",
                params: {
                    'type': 'category'
                }
            })
            .state("home.scorecard", {
                url: "/scorecard",
                templateUrl: "app/home/scorecard/scorecard.html",
                data: { pageTitle: "Scorecard - Categorias" },
                controller: "ScorecardCtrl",
                controllerAs: "ct"
            })
            .state("inventory", {
                abstract: true,
                url: "/inventory",
                templateUrl: "app/components/common/content.html"
            })
            .state("inventory.stockAnalysis", {
                url: "/stockAnalysis",
                templateUrl: "app/inventory/stockAnalysis/stockAnalysis.html",
                data: { pageTitle: "Analisis de Inventarios" },
                controller: "StockAnalysisCtrl",
                controllerAs: "ct"
            })

            .state("home.assortmentReport", {
                url: "/assortmentReport",
                templateUrl: "app/home/assortmentReport/assortmentReport.html",
                data: { pageTitle: "Assortment Report" },
                controller: "AssortmentReportCtrl",
                controllerAs: "ct"
            })

            .state("prices", {
                abstract: true,
                url: "/prices",
                templateUrl: "app/components/common/content.html"
            })
            .state("prices.volumeMeasurement", {
                url: "/volumeMeasurement",
                templateUrl: "app/prices/reports/volumeMeasurement.html",
                data: { pageTitle: "Medici�n de Volumen" },
                controller: "VolumeMeasurementCtrl",
                controllerAs: "ct"
            })
            .state("prices.evolutionReport", {
                url: "/evolutionReport",
                templateUrl: "app/prices/reports/priceEvolutionReport.html",
                data: { pageTitle: "Reporte Evoluci�n de Precios" },
                controller: "PriceEvolutionReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.weeklyReport", {
                url: "/weeklyReport",
                templateUrl: "app/prices/reports/priceWeeklyReport.html",
                data: { pageTitle: "Reporte semanal" },
                controller: "PriceWeeklyReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.informedPrices", {
                url: "/informedPrices",
                templateUrl: "app/prices/reports/informedPricesReport.html",
                data: { pageTitle: "Reporte semanal" },
                controller: "InformedPricesReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.competitorsPrices", {
                url: "/competitorsPrices",
                templateUrl: "app/prices/reports/competitorsPrices.html",
                data: { pageTitle: "Reporte Precios de Competidores" },
                controller: "CompetitorsPricesReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.dailyMarginReport", {
                url: "/dailyMarginReport",
                templateUrl: "app/prices/reports/dailyMarginReport.html",
                data: { pageTitle: "Reporte de Margenes Diarios" },
                controller: "DailyMarginReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.competitiveIndex", {
                url: "/competitiveIndex",
                templateUrl: "app/prices/reports/competitiveIndex.html",
                data: { pageTitle: "Indice de Competitividad" },
                controller: "CompetitiveIndexCtrl",
                controllerAs: "ct"
            })
            .state("prices.competitivePositionReport", {
                url: "/competitivePositionReport",
                templateUrl: "app/prices/reports/priceCompetitivePositionReport.html",
                data: { pageTitle: "Gr�fico de posici�n Competitiva" },
                controller: "PriceCompetitivePositionReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.competitiveReport", {
                url: "/competitiveReport",
                templateUrl: "app/prices/reports/priceCompetitiveReport.html",
                data: { pageTitle: "Gr�fico de Competitividad" },
                controller: "PriceCompetitiveReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.itemSensitivities", {
                url: "/itemSensitivities",
                templateUrl: "app/prices/itemSensitivities/itemSensitivities.html",
                data: { pageTitle: "Sensibilidad de Precios" },
                controller: "ItemSensitivitiesCtrl",
                controllerAs: "ct"
            })
            .state("prices.editItemSensitivities", {
                url: "/itemSensitivities/:itemSensitivityId",
                templateUrl: "app/prices/itemSensitivities/editItemSensitivity.html",
                data: { pageTitle: "Sensibilidad de Precios" },
                controller: "EditItemSensitivityCtrl",
                controllerAs: "ct"
            })
            .state("prices.categorySummary", {
                url: "/category-summary",
                templateUrl: "app/prices/categorySummary/category-summary.html",
                data: { pageTitle: "Decisions" },
                controller: "CategorySummaryCtrl",
                controllerAs: "ct"
            })
            .state("prices.competitors", {
                url: "/competitors",
                templateUrl: "app/prices/competitors/competitors.html",
                data: { pageTitle: "Competidores" },
                controller: "CompetitorsCtrl",
                controllerAs: "ct"
            })
            .state("prices.priceMap", {
                url: "/priceMap/:itemId/:priceListId",
                templateUrl: "app/prices/priceMap/priceMap.html",
                data: { pageTitle: "Mapa de Precios" },
                params: {
                    'itemId': '0',
                    'priceListId': '0'
                },
                controller: "PriceMapCtrl",
                controllerAs: "ct"
            })
            .state("prices.decisions", {
                url: "/decisions/:categoryId?endorse=:isEndorse&catg=:categoryGroup&cat=:category&subcat=:subcategory&newf=:new",
                templateUrl: companyJson.Navigation.UsePriceRedesign ? "app/prices/decisions/redesign/decisions.html" : "app/prices/decisions/decisions.html",
                data: { pageTitle: "Decisions" },
                params: {
                    'categoryId': '0',
                    'categoryGroup': '',
                    'category': '',
                    'subcategory': '',
                    'isEndorse': '',
                    'new': 'false'
                },
                controller: companyJson.Navigation.UsePriceRedesign ? "PriceDecisionsCtrl" : "DecisionsCtrl",
                controllerAs: "ct"
            })
            .state("prices.decisionsRedesigned", {
                url: "/decisions/redesign/:categoryId?endorse=:isEndorse&catg=:categoryGroup&cat=:category&subcat=:subcategory&newf=:new",
                templateUrl: "app/prices/decisions/redesign/decisions.html",
                data: { pageTitle: "Decisions" },
                params: {
                    'categoryId': '0',
                    'categoryGroup': '',
                    'category': '',
                    'subcategory': '',
                    'isEndorse': '',
                    'new': 'false'
                },
                controller: "PriceDecisionsCtrl",
                controllerAs: "ct"
            })
            .state("prices.rulesCategory", {
                url: "/rules",
                templateUrl: "app/prices/rulesCategory/rules-category.html",
                data: { pageTitle: "Estrategias y Reglas de Precios" },
                controller: "PricesRulesCategoryCtrl",
                controllerAs: "ct"
            })
            .state("prices.rules", {
                url: "/rules/:categoryId/:categoryGroup/:category/:subcategory",
                templateUrl: "app/prices/rules/rules.html",
                data: { pageTitle: "Estrategias y Reglas de Precios" },
                controller: "PricesRulesCtrl",
                controllerAs: "ct"
            })
            .state("prices.toInform", {
                url: "/output/toInform",
                templateUrl: companyJson.Navigation.InformToErpWs ? "app/prices/outputWS/priceOutputWS.html" : "app/prices/output/priceOutput.html",
                data: { pageTitle: "Informar al ERP" },
                controller: companyJson.Navigation.InformToErpWs ? "priceOutputWSCtrl" : "priceOutputCtrl",
                controllerAs: "ct"
            })
            .state("prices.abTesting", {
                url: "/abTesting",
                templateUrl: "app/prices/abTesting/abTesting.html",
                data: { pageTitle: "AB Testing" },
                controller: "AbTestingCtrl",
                controllerAs: "ct"
            })
            .state("prices.abTestingIndex", {
                url: "/abTestingIndex",
                templateUrl: "app/prices/abTestingIndex/abTestingIndex.html",
                data: { pageTitle: "AB Testing" },
                controller: "AbTestingIndexCtrl",
                controllerAs: "ct"
            })
            .state("prices.abTestingReports", {
                url: "/abTestingReports/:abTestingId",
                templateUrl: "app/prices/abTestingReports/abTestingReports.html",
                data: { pageTitle: "AB Testing reporte" },
                controller: "AbTestingReportsCtrl",
                controllerAs: "ct",
                params: {
                    'abTestingId': '0',
                }
            })
            .state("prices.priceChangesReport", {
                url: "/reports/priceChangesReport",
                templateUrl: "app/prices/reports/priceChangesReport.html",
                data: { pageTitle: "Reporte de Cambio de Precios" },
                controller: "PriceChangesReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.priceMapReport", {
                url: "/reports/priceMapReport",
                templateUrl: "app/prices/reports/priceMapReport.html",
                data: { pageTitle: "Reporte de mapa de Precios" },
                controller: "PriceMapReportCtrl",
                controllerAs: "ct"
            })
            .state("prices.costs", {
                url: "/costs",
                templateUrl: "app/prices/costs/costs.html",
                data: { pageTitle: "Costos de Reposicion" },
                controller: "CostsCtrl",
                controllerAs: "ct"
            })
            .state("prices.editCost", {
                url: "/editCost",
                templateUrl: "app/prices/costs/editCost.html",
                data: { pageTitle: "Nuevo Costo de Reposicion" },
                controller: "EditCostCtrl",
                controllerAs: "ct"
            })
            .state("prices.priceLists", {
                url: "/pricelists",
                templateUrl: "app/prices/pricelists/pricelists.html",
                data: { pageTitle: "Zona de Precios" },
                controller: "PricelistsCtrl",
                controllerAs: "ct"
            })
            .state("prices.editPricelist", {
                url: "/editPricelist/:priceListId",
                templateUrl: "app/prices/pricelists/editPricelist.html",
                data: { pageTitle: "Zona de Precios" },
                controller: "EditPricelistCtrl",
                params: {
                    'priceListId': '0'
                },
                controllerAs: "ct"
            })
            .state("prices.priceListsSubsidies", {
                url: "/pricelistsSubsidies",
                templateUrl: "app/prices/pricelistsSubsidies/pricelistsSubsidies.html",
                data: { pageTitle: "Subsidios" },
                controller: "PricelistsSubsidiesCtrl",
                controllerAs: "ct"
            })
            .state("prices.editPricelistSubsidies", {
                url: "/editPricelistSubsidies/:priceListSubsidyId",
                templateUrl: "app/prices/pricelistsSubsidies/editPricelistSubsidies.html",
                data: { pageTitle: "Subsidios" },
                controller: "EditPricelistSubsidiesCtrl",
                params: {
                    'priceListSubsidiesId': '0'
                },
                controllerAs: "ct"
            })
            .state("prices.regions", {
                url: "/regions",
                templateUrl: "app/prices/regions/regions.html",
                data: { pageTitle: "Regiones" },
                controller: "PriceRegionsCtrl",
                controllerAs: "ct"
            })
            .state("prices.editRegion", {
                url: "/editRegions/:regionId",
                templateUrl: "app/prices/regions/editRegion.html",
                data: { pageTitle: "Regiones" },
                controller: "EditPriceRegionCtrl",
                params: {
                    'regionId': '0'
                },
                controllerAs: "ct"
            })
            .state("assortment", {
                abstract: true,
                url: "/assortment",
                templateUrl: "app/components/common/content.html"
            })
            .state("assortment.categorySummary", {
                url: "/category-summary",
                templateUrl: "app/assortment/categorySummary/category-summary.html",
                data: { pageTitle: "Analisis de Surtido" },
                controller: "AssortmentCategorySummaryCtrl",
                controllerAs: "ct"
            })
            .state("assortment.decisions", {
                url: "/decisions/:categoryId/:subcategoryId/:clusterId?endorse=:isEndorse&catg=:categoryGroup&cat=:category&subcat=:subcategory",
                templateUrl: "app/assortment/decisions/decisions.html",
                data: { pageTitle: "Decisions" },
                params: {
                    'categoryId': '0',
                    'subcategoryId': '0',
                    'categoryGroup': '',
                    'category': '',
                    'subcategory': '',
                    'clusterId': '0',
                    'isEndorse': ''
                },
                controller: "AssortmentDecisionsCtrl",
                controllerAs: "ct"
            })
            .state("assortment.rules", {
                url: "/rules/:categoryId/:categoryGroup/:category/:subcategory",
                templateUrl: "app/assortment/rules/rules.html",
                data: { pageTitle: "Reglas de Surtido" },
                controller: "AssortmentRulesCtrl",
                controllerAs: "ct"
            })
            .state("assortment.rulesCategory", {
                url: "/rules",
                templateUrl: "app/assortment/rulesCategory/rules-category.html",
                data: { pageTitle: "Estrategias y Reglas de Surtido" },
                controller: "AssortmentRulesCategoryCtrl",
                controllerAs: "ct"
            })
            .state("assortment.outputErp", {
                url: "/output",
                templateUrl: "app/assortment/output/output.html",
                data: { pageTitle: "Salida ERP" },
                controller: "AssortmentOutputCtrl",
                controllerAs: "ct"
            })
            .state("assortment.outputErpCencosudColombia", {
                url: "/outputCencosudColombia",
                templateUrl: "app/assortment/output/outputColombia.html",
                data: { pageTitle: "Salida ERP" },
                controller: "AssortmentOutputColombiaCtrl",
                controllerAs: "ct"
            })
            .state("assortment.outputErpCencosudChile", {
                url: "/outputCencosudhile",
                templateUrl: "app/assortment/output/outputChile.html",
                data: { pageTitle: "Salida ERP" },
                controller: "AssortmentOutputChileCtrl",
                controllerAs: "ct"
            })
            .state("assortment.outputPrismaErp", {
                url: "/outputPrisma",
                templateUrl: "app/assortment/output/outputPrisma.html",
                data: { pageTitle: "Salida ERP" },
                controller: "AssortmentOutputPrismaCtrl",
                controllerAs: "ct"
            })
            .state("assortment.summaryReport", {
                url: "/summaryReport",
                templateUrl: "app/assortment/summaryReport/summary-report.html",
                data: { pageTitle: "Reporte Estatico y Dinamico" },
                controller: "AssortmentSummaryReportCtrl",
                controllerAs: "ct"
            })
            .state("assortment.clusterHierarchy", {
                url: "/clusterHierarchy",
                templateUrl: "app/assortment/clusterHierarchy/clusterHierarchy.html",
                data: { pageTitle: "Reporte de Cascadeo" },
                controller: "AssortmentClusterHierarchyCtrl",
                controllerAs: "ct"
            })
            .state("admin", {
                abstract: true,
                url: "/admin",
                templateUrl: "app/components/common/content.html"
            })
            .state("admin.importers", {
                abstract: true,
                url: "/importers",
                templateUrl: "app/components/common/empty_view.html"
            })
            .state("admin.importer", {
                url: "/importer/:importerType",
                params: { "importerType": "" },
                templateUrl: "app/admin/importers/importer.html",
                data: { pageTitle: "Importer" },
                controller: "ImporterCtrl",
                controllerAs: "ct"
            })
            .state("admin.cluster", {
                url: "/clusters",
                templateUrl: "app/admin/masters/cluster/cluster.html",
                data: { pageTitle: "Cluster" },
                controller: "ClusterCtrl",
                controllerAs: "ct"
            })
            .state("admin.stores", {
                url: "/stores",
                templateUrl: "app/admin/masters/stores/stores.html",
                data: { pageTitle: "Stores" },
                controller: "StoresCtrl",
                controllerAs: "ct"
            })
            .state("admin.editStores", {
                url: "/stores/:storeId",
                templateUrl: "app/admin/masters/stores/editStores.html",
                data: { pageTitle: "Stores" },
                controller: "EditStoresCtrl",
                controllerAs: "ct",
                params: {
                    'storeId': '0',
                }
            })
            .state("admin.items", {
                url: "/items",
                templateUrl: "app/admin/masters/items/items.html",
                data: { pageTitle: "Items" },
                controller: "ItemsCtrl",
                controllerAs: "ct"
            })
            .state("admin.editItem", {
                url: "/item/:itemId",
                templateUrl: "app/admin/masters/items/editItem.html",
                data: { pageTitle: "Items" },
                controller: "EditMasterItemCtrl",
                params: {
                    'itemId': '0'
                },
                controllerAs: "ct"
            })
            .state("admin.brands", {
                url: "/brands",
                templateUrl: "app/admin/masters/brands/brands.html",
                data: { pageTitle: "Brands" },
                controller: "BrandsCtrl",
                controllerAs: "ct"
            })
            .state("admin.managementItems", {
                url: "/managementItems",
                templateUrl: "app/admin/masters/managementItems/managementItems.html",
                data: { pageTitle: "managementItems" },
                controller: "ManagementItemsCtrl",
                controllerAs: "ct"
            })
            .state("admin.missions", {
                url: "/missions",
                templateUrl: "app/admin/missions/missions.html",
                data: { pageTitle: "Missions" },
                controller: "MissionsCtrl",
                controllerAs: "ct"
            })
            .state("admin.editMissions", {
                url: "/Missions/:missionId",
                templateUrl: "app/admin/Missions/editMissions.html",
                data: { pageTitle: "Missions" },
                controller: "EditMissionsCtrl",
                controllerAs: "ct",
                params: {
                    'MissionsId': '0',
                }
            })
            .state("admin.interfaces", {
                url: "/interfaces/:batchIdentifier",
                params: { "batchIdentifier": "" },
                templateUrl: "app/admin/interface/interface.html",
                data: { pageTitle: "Interfaces" },
                controller: "InterfaceCtrl",
                controllerAs: "ct"
            })
            .state("admin.reports", {
                abstract: true,
                url: "/reports",
                templateUrl: "app/components/common/empty_view.html"
            })
            .state("admin.reports.itemsToIgnore", {
                url: "/items-to-ignore",
                templateUrl: "app/admin/reports/itemsToIgnore/itemsToIgnore.html",
                data: { pageTitle: "ItemsToIgnore" },
                controller: "ItemsToIgnoreCtrl",
                controllerAs: "ct"
            })
            .state("admin.security", {
                abstract: true,
                url: "/security",
                templateUrl: "app/components/common/empty_view.html"
            })
            .state("admin.security.roles", {
                url: "/roles",
                templateUrl: "app/admin/security/roles/roles.html",
                data: { pageTitle: "Roles" },
                controller: "RolesCtrl",
                controllerAs: "ct"
            })
            .state("admin.security.users", {
                url: "/users",
                templateUrl: "app/admin/security/users/users.html",
                data: { pageTitle: "Roles" },
                controller: "UsersCtrl",
                controllerAs: "ct"
            })
            .state("admin.security.audit", {
                url: "/audit",
                templateUrl: "app/admin/security/audit/audit.html",
                data: { pageTitle: "Auditoria" },
                controller: "AuditCtrl",
                controllerAs: "ct"
            })
            .state("admin.security.reports", {
                url: "/reports",
                templateUrl: "app/admin/security/reports/reports.html",
                data: { pageTitle: "Reportes" },
                controller: "ReportsCtrl",
                controllerAs: "ct"
            })
            .state("promotions", {
                abstract: true,
                url: "/promotions",
                templateUrl: "app/components/common/content.html"
            })
            .state("promotions.promotionalProcesses", {
                url: "/promotionalProcesses",
                templateUrl: "app/promotions/promotionalProcesses/promotionalProcesses.html",
                data: { pageTitle: "test" },
                controller: "promotionalProcessesCtrl",
                controllerAs: "ct"
            })
            .state("promotions.promotions", {
                url: "/promotions",
                templateUrl: "app/promotions/promotions/promotions.html",
                data: { pageTitle: "Acciones Promocionales" },
                controller: "promotionsCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editPromotion", {
                url: "/promotions/:promotionId/:taskId/:promotionType/:copy/:readOnly",
                templateUrl: "app/promotions/promotions/editPromotion.html",
                data: { pageTitle: "Editar Accion Promocional" },
                params: {
                    'promotionId': '0',
                    'taskId': '0',
                    'promotionType': '',
                    'copy': 'false',
                    'readOnly':'false'
                },
                controller: "editPromotionCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editInternalPromotion", {
                url: "/internalPromotions/:promotionId/:promotionMediaPageId/:readOnly/:copy/:rejected",
                templateUrl: "app/promotions/promotions/editInternalPromotion.html",
                data: { pageTitle: "Editar Accion Promocional" },
                params: {
                    'promotionId': '0',
                    'promotionMediaPageId': '0',
                    'readOnly': 'false',
                    'copy': 'false',
                    'rejected': 'false'
                },
                controller: "editInternalPromotionCtrl",
                controllerAs: "ct"
            })
            .state("promotions.promotionSearch", {
                url: "/promotionSearch",
                templateUrl: "app/promotions/promotions/promotionSearch.html",
                data: { pageTitle: "Busqueda avanzada" },
                controller: "promotionSearchCtrl",
                controllerAs: "ct"
            }) 
            .state("promotions.layouts", {
                url: "/layouts",
                templateUrl: "app/promotions/layouts/layouts.html",
                data: { pageTitle: "Layouts de P�ginas" },
                controller: "layoutsCtrl",
                controllerAs: "ct"
            })
            .state("promotions.rules", {
                url: "/rules",
                templateUrl: "app/promotions/rules/promotionRules.html",
                data: { pageTitle: "Promotion Rules" },
                controller: "promotionRulesCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editRule", {
                url: "/rules/edit/:promotionRuleId",
                templateUrl: "app/promotions/rules/editPromotionRule.html",
                data: { pageTitle: "Promotion Rules" },
                params: {
                    'promotionRuleId': '0'
                },
                controller: "editPromotionRuleCtrl",
                controllerAs: "ct"
            })
            .state("promotions.layout", {
                url: "/layouts/:layoutId",
                templateUrl: "app/promotions/layouts/editLayout.html",
                data: { pageTitle: "Editar o Crear Layout" },
                params: {
                    'layoutId': '0'
                },
                controller: "EditPromotionLayoutCtrl",
                controllerAs: "ct"
            })
            .state("promotions.controlPanel", {
                url: "/controlPanel",
                templateUrl: "app/promotions/controlPanel/controlPanel.html",
                data: { pageTitle: "test" },
                controller: "controlPanelCtrl",
                controllerAs: "ct"
            })
            .state("promotions.comunication", {
                url: "/comunication/:taskId",
                templateUrl: "app/promotions/comunication/comunication.html",
                data: { pageTitle: "Acciones Promocionales Vigentes" },
                params: {
                    'taskId': '0'
                },
                controller: "comunicationCtrl",
                controllerAs: "ct"
            })
            .state("promotions.prices", {
                url: "/prices/:promotionId/:taskId",
                templateUrl: "app/promotions/prices/prices.html",
                data: { pageTitle: "Cierre de Precios" },
                params: {
                    'promotionId': '0',
                    'taskId': '0'
                },
                controller: "pricesCtrl",
                controllerAs: "ct"
            })
            .state("promotions.campaigns", {
                url: "/campaigns",
                templateUrl: "app/promotions/campaigns/campaigns.html",
                data: { pageTitle: "Campa�as Promocionales" },
                controller: "promotionalCampaignsCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editcampaign", {
                url: "/campaigns/:campaignId",
                templateUrl: "app/promotions/campaigns/editCampaign.html",
                data: { pageTitle: "Campa�a Promocional" },
                params: {
                    'campaignId': '0'
                },
                controller: "editPromotionalCampaignCtrl",
                controllerAs: "ct"
            })
            .state("promotions.workflows", {
                url: "/workflows",
                templateUrl: "app/promotions/workflows/workflows.html",
                data: { pageTitle: "Procesos Promocionales" },
                controller: "promotionalWorkflowsCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editworkflow", {
                url: "/workflows/:workflowId",
                templateUrl: "app/promotions/workflows/editWorkflow.html",
                data: { pageTitle: "Proceso Promocional" },
                params: {
                    'workflowId': '0'
                },
                controller: "editPromotionalWorkflowCtrl",
                controllerAs: "ct"
            })
            .state("promotions.layoutsassignment", {
                url: "/layoutsassignment/:promotionId/:taskId",
                templateUrl: "app/promotions/layouts/layoutsAssignment.html",
                data: { pageTitle: "Asignar Layouts" },
                params: {
                    'promotionId': '0',
                    'taskId': '0'
                },
                controller: "layoutsAssignmentCtrl",
                controllerAs: "ct"
            })
            .state("promotions.myTasks", {
                url: "/myTasks",
                templateUrl: "app/promotions/myTasks/myTasks.html",
                data: { pageTitle: "test" },
                controller: "myTasksCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editItem", {
                url: "/editItem/:promotionMediaPageId/:taskId/:position/:isHighlighted/:isSubstitute/:promotionItemId/:promotionId/:promotionItemType/:salesUnitOfMeasureId",
                templateUrl: "app/promotions/items/editItem.html",
                data: { pageTitle: "Editar Item Relacionado a la Promocion" },
                params: {
                    'promotionMediaPageId': '0',
                    'position': '0',
                    'isHighlighted': 'False',
                    'isSubstitute': 'False',
                    'taskId': '0',
                    'promotionItemId': '0',
                    'promotionId': '0',
                    'promotionItemType': '0',
                    'salesUnitOfMeasureId': '0'
                },
                controller: "editItemCtrl",
                controllerAs: "ct"
            })
            .state("promotions.editMechanic", {
                url: "/editMechanic/:promotionMediaPageId/:taskId/:position/:isHighlighted/:isSubstitute/:promotionItemId/:promotionMechanicId/:promotionId/:promotionItemType/:isEditPanguiCode",
                templateUrl: "app/promotions/mechanics/editMechanic.html",
                data: { pageTitle: "Editar Mecanica Relacionada a la Promocion" },
                params: {
                    'promotionMediaPageId': '0',
                    'taskId': '0',
                    'position': '0',
                    'isHighlighted': 'False',
                    'isSubstitute': 'False',
                    'promotionItemId': '0',
                    'promotionMechanicId': '0',
                    'promotionId': '0',
                    'promotionItemType': '0',
                    'isEditPanguiCode': 'False'
                },
                controller: "editMechanicCtrl",
                controllerAs: "ct"
            })
            .state("promotions.calendar", {
                url: "/calendar/:promotionId/:taskId",
                templateUrl: "app/promotions/promotions/calendar.html",
                data: { pageTitle: "Calendario Promocional" },
                params: {
                    'promotionId': '0',
                    'taskId': '0'
                },
                controller: "promotionsCalendarCtrl",
                controllerAs: "ct"
            })
            .state("promotions.addItemToLayout", {
                url: "/addItemToLayout/:promotionId/:taskId/:promotionMediaPageId",
                templateUrl: "app/promotions/addItemToLayout/addItemToLayout.html",
                data: { pageTitle: "Agregar Items" },
                params: {
                    'promotionId': '0',
                    'taskId': '0',
                    'promotionMediaPageId': '0',
                    'isLayoutImageUpload': 'false',
                    'isPrintLayoutCompare': 'false'
                },
                controller: "addItemToLayoutCtrl",
                controllerAs: "ct"
            })
            .state("promotions.printLayoutCompare", {
                url: "/printLayoutCompare/:promotionId/:taskId/:promotionMediaPageId",
                templateUrl: "app/promotions/addItemToLayout/addItemToLayout.html",
                data: { pageTitle: "Agregar Items" },
                params: {
                    'promotionId': '0',
                    'taskId': '0',
                    'promotionMediaPageId': '0',
                    'isLayoutImageUpload': 'false',
                    'isPrintLayoutCompare': 'true'
                },
                controller: "addItemToLayoutCtrl",
                controllerAs: "ct"
            })
            .state("promotions.layoutImageUpload", {
                url: "/layoutImageUpload/:promotionId/:taskId/:promotionMediaPageId",
                templateUrl: "app/promotions/addItemToLayout/addItemToLayout.html",
                data: { pageTitle: "Agregar Items" },
                params: {
                    'promotionId': '0',
                    'taskId': '0',
                    'promotionMediaPageId': '0',
                    'isLayoutImageUpload': 'true',
                    'isPrintLayoutCompare': 'false'
                },
                controller: "addItemToLayoutCtrl",
                controllerAs: "ct"
            })
            .state("promotions.finalValidation", {
                url: "/finalValidation/:promotionId/:taskId/:promotionMediaPageId",
                templateUrl: "app/promotions/addItemToLayout/addItemToLayout.html",
                data: { pageTitle: "validacion final" },
                params: {
                    'taskId': '0',
                    'isLayoutImageUpload': 'true',
                    'isPrintLayoutCompare': 'true'
                },
                controller: "addItemToLayoutCtrl",
                controllerAs: "ct"
            })
            .state("promotions.forecastImporter", {
                url: "/forecastImporter/:promotionId/:taskId",
                templateUrl: "app/promotions/items/forecastImporter.html",
                data: { pageTitle: "Agregar Items" },
                params: {
                    'promotionId': '0',
                    'taskId': '0',
                    'promotionMediaPageId': '0'
                },
                controller: "forecastImporterCtrl",
                controllerAs: "ct"
            })
            .state("promotions.supplying", {
                url: "/supplying/:promotionId/:taskId",
                templateUrl: "app/promotions/supplying/supplying.html",
                data: { pageTitle: "Abastecimiento" },
                params: {
                    'taskId': '0',
                    'promotionId': '0'
                },
                controller: "supplyingCtrl",
                controllerAs: "ct"
            })
            .state("promotions.AssortmentActivation", {
                url: "/AssortmentActivation/:taskId",
                templateUrl: "app/promotions/AssortmentActivation/AssortmentActivation.html",
                data: { pageTitle: "Activacion de surtido" },
                params: {
                    'taskId': '0'
                },
                controller: "AssortmentActivationCtrl",
                controllerAs: "ct"
            })
            .state("promotions.storeControlAndExhibition", {
                url: "/storeControlAndExhibition/:taskId",
                templateUrl: "app/promotions/storeControlAndExhibition/storeControlAndExhibition.html",
                data: { pageTitle: "Control y Exhibicion en tienda" },
                params: {
                    'taskId': '0'
                },
                controller: "storeControlAndExhibitionCtrl",
                controllerAs: "ct"
            })
            .state("promotions.2reviewTradeAgreements", {
                url: "/reviewTradeAgreements/:promotionId/:taskId/:promotionMediaPageId",
                templateUrl: "app/promotions/addItemToLayout/addItemToLayout.html",
                data: { pageTitle: "Revision de acuerdos comerciales" },
                params: {
                    'promotionId': '0',
                    'taskId': '0',
                    'promotionMediaPageId': '0',
                    'isLayoutImageUpload': 'false',
                    'isPrintLayoutCompare': 'false',
                    'isLayoutForReview': 'true'
                },
                controller: "addItemToLayoutCtrl",
                controllerAs: "ct"
            })
            .state("promotions.activation", {
                url: "/activation/:taskId/:promotionId",
                templateUrl: "app/promotions/activation/activation.html",
                data: { pageTitle: "Activacion" },
                params: {
                    'taskId': '0',
                    'promotionId': '0'
                },
                controller: "activationCtrl",
                controllerAs: "ct"
            })
            .state("promotions.reviewTradeAgreements", {
                url: "/reviewTradeAgreements/:taskId",
                templateUrl: "app/promotions/reviewTradeAgreements/reviewTradeAgreements.html",
                data: { pageTitle: "Revision de acuerdos comerciales" },
                params: {
                    'taskId': '0'
                },
                controller: "reviewTradeAgreementsCtrl",
                controllerAs: "ct"
            })
            .state("promotions.priceSurveys", {
                url: "/priceSurveys/:taskId",
                templateUrl: "app/promotions/priceSurveys/priceSurveys.html",
                data: { pageTitle: "Relevamiento de precios" },
                params: {
                    'taskId': '0'
                },
                controller: "priceSurveysCtrl",
                controllerAs: "ct"
            })
            .state("promotions.downloadBIReport", {
                url: "/BIReport",
                templateUrl: "app/promotions/promotions/BIReport.html",
                data: { pageTitle: "Descargar reporte para BI" },
                controller: "BIReportCtrl",
                controllerAs: "ct"
            })
            .state("promotions.downloadOverlappingReport", {
                url: "/overlappingReport",
                templateUrl: "app/promotions/promotions/overlappingReport.html",
                data: { pageTitle: "Descargar reporte de solapamientos" },
                controller: "overlappingReportCtrl",
                controllerAs: "ct"
            })
            .state("promotions.reports", {
                url: "/reports",
                templateUrl: "app/promotions/reports/reports.html",
                data: { pageTitle: "Reportes" },
                controller: "reportsCtrl",
                controllerAs: "ct"
            })
            .state("prices.priceRoundings", {
                url: "/priceRoundings",
                templateUrl: "app/prices/priceRoundings/priceRoundings.html",
                data: { pageTitle: "Reglas de Redondeo" },
                controller: "PriceRoundingsCtrl",
                controllerAs: "ct"
            })
            .state("prices.editPriceRoundings", {
                url: "/priceRoundings/:priceTypeId",
                templateUrl: "app/prices/priceRoundings/editPriceRoundings.html",
                data: { pageTitle: "Regla de Redondeo" },
                params: {
                    'priceTypeId': '-1'
                },
                controller: "editPriceRoundingsCtrl",
                controllerAs: "ct"
            })
            .state("spaces", {
                abstract: true,
                url: "/spaces",
                templateUrl: "app/components/common/content.html"
            })
            .state("spaces.createPlanogram", {
                url: "/planograms/create/:planogramId/:copyFromPlanogramId",
                templateUrl: "app/spaces/planograms/createPlanogram.html",
                params: {
                    planogramId: '0',
                    copyFromPlanogramId: '0'
                },
                controller: "CreatePlanogramCtrl",
                controllerAs: "ct"
            })
            .state("spaces.createPlanogramOld", {
                // TODO: Eliminar al terminar de integrar la edici�n SVG.
                url: "/planograms/createold/:planogramId/:copyFromPlanogramId",
                templateUrl: "app/spaces/planograms/createPlanogram.html",
                params: {
                    planogramId: '0',
                    copyFromPlanogramId: '0'
                },
                controller: "CreatePlanogramCtrl",
                controllerAs: "ct"
            })
            .state("spaces.importPlanogram", {
                url: "/planograms/import",
                templateUrl: "app/spaces/planograms/createPlanogram.html",
                controller: "CreatePlanogramCtrl",
                controllerAs: "ct"
            })
            .state("spaces.editPlanogram", {
                url: "/planograms/edit/:planogramId/:itemId/:clusterId",
                templateUrl: "app/spaces/planograms/editPlanogram.html",
                params: {
                    planogramId: '0',
                    itemId: '0',
                    clusterId: '0'
                },
                controller: "EditPlanogramCtrl",
                controllerAs: "ct"
            })
            // TODO: Eliminar al terminar de integrar la edici�n SVG.
            .state("spaces.editPlanogramOld", {
                url: "/planograms/editold/:planogramId/:itemId/:clusterId",
                templateUrl: "app/spaces/planograms/editPlanogram.html",
                params: {
                    planogramId: '0',
                    itemId: '0',
                    clusterId: '0'
                },
                controller: "EditPlanogramCtrl",
                controllerAs: "ct"
            })
            .state("spaces.viewPlanogram", {
                url: "/planograms/view/:planogramId",
                templateUrl: "app/spaces/planograms/editPlanogram.html",
                params: { planogramId: '1' },
                controller: "EditPlanogramCtrl",
                controllerAs: "ct"
            })
             //TODO: Eliminar al terminar de integrar la edici�n SVG.
            .state("spaces.viewPlanogramOld", {
                url: "/planograms/viewold/:planogramId",
                templateUrl: "app/spaces/planograms/editPlanogram.html",
                params: { planogramId: '1' },
                controller: "EditPlanogramCtrl",
                controllerAs: "ct"
            })
            .state("spaces.editLayout", {
                url: "/layouts/edit/:layoutId",
                templateUrl: "app/spaces/layouts/editLayout.html",
                data: { pageTitle: "test" },
                params: {
                    'layoutId': '0'
                },
                controller: "editSpacesLayoutCtrl",
                controllerAs: "ct"
            })


            .state("markdowns", {
                abstract: true,
                url: "/markdowns",
                templateUrl: "app/components/common/content.html"
            })
            .state("markdowns.categorySummary", {
                url: "/summary",
                templateUrl: "app/markdowns/categorySummary/category-summary-markdowns.html",
                data: { pageTitle: "Decisiones de liquidaciones" },
                controller: "CategorySummaryMarkdownsCtrl",
                controllerAs: "ct"
            })
            .state("markdowns.decisions", {
                url: "/decisions/:categoryId?catg=:categoryGroup&cat=:category&type=:decisionType",
                templateUrl: "app/markdowns/decisions/markdownDecisions.html",
                data: { pageTitle: "Decisiones de liquidaciones" },
                controller: "MarkdownDecisionsCtrl",
                controllerAs: "ct",

                //url: "/decisions/:categoryId?endorse=:isEndorse&catg=:categoryGroup&cat=:category&subcat=:subcategory&newf=:new",
                params: {
                    'categoryId': '0',
                    'categoryGroup': '',
                    'category': '',
                    'decisionType': '0'
                },
            })
            .state("markdowns.rulesCategory", {
                url: "/rules",
                templateUrl: "app/markdowns/rulesCategory/markdownRulesCategories.html",
                data: { pageTitle: "Estrategias y Reglas de liquidaciones" },
                controller: "MarkdownRulesCategoryCtrl",
                controllerAs: "ct"
            })
            .state("markdowns.rulesSelection", {
                url: "/rulesSelection",
                templateUrl: "app/markdowns/rulesSelection/markdownRulesSelection.html",
                data: { pageTitle: "Seleccion de reglas" },
                controller: "MarkdownRulesSelectionCtrl",
                controllerAs: "ct"
            })
            .state("markdowns.rulesCreation", {
                url: "/rulesCreation",
                templateUrl: "app/markdowns/rulesCreation/markdownRulesCreation.html",
                data: { pageTitle: "Creacion de reglas" },
                controller: "MarkdownRulesCreationCtrl",
                controllerAs: "ct"
            })
            .state("markdowns.activation", {
                url: "/activation",
                templateUrl: "app/markdowns/activation/markdownActivation.html",
                data: { pageTitle: "Activacion" },
                controller: "MarkdownActivationCtrl",
                controllerAs: "ct"
            })
            .state("markdowns.importer", {
                url: "/importer/:importerType",
                params: { "importerType": "" },
                templateUrl: "app/admin/importers/importer.html",
                data: { pageTitle: "Importer" },
                controller: "ImporterCtrl",
                controllerAs: "ct"
            })
            .state("spaces.viewLayout", {
                url: "/layouts/view/:layoutId",
                templateUrl: "app/spaces/layouts/editLayout.html",
                data: { pageTitle: "test" },
                params: {
                    'layoutId': '0',
                },
                controller: "editSpacesLayoutCtrl",
                controllerAs: "ct"
            })
            .state("spaces.viewLayoutWithChange", {
                url: "/layouts/view/:layoutId/:planogramIdChange",
                templateUrl: "app/spaces/layouts/editLayout.html",
                data: { pageTitle: "test" },
                params: {
                    'layoutId': '0',
                    'planogramIdChange': '0'
                },
                controller: "editSpacesLayoutCtrl",
                controllerAs: "ct"
            })
            .state("spaces.layouts", {
                url: "/layouts",
                templateUrl: "app/spaces/layouts/layouts.html",
                data: { pageTitle: "test" },
                controller: "spacesLayoutsCtrl",
                controllerAs: "ct"
            })
            .state("spaces.generalKpis", {
                url: "/generalKpis",
                templateUrl: "app/spaces/reports/generalKpis.html",
                data: { pageTitle: "KPIs Generales" },
                controller: "GeneralKpisCtrl",
                controllerAs: "ct"
            })
            .state("spaces.itemsByAssortment", {
                url: "/itemsByAssortment",
                templateUrl: "app/spaces/reports/itemsByAssortment.html",
                data: { pageTitle: "Articulos por Surtido" },
                controller: "ItemsByAssortmentCtrl",
                controllerAs: "ct"
            })
            .state("spaces.tickets", {
                url: "/tickets/:planogramLevelItemId/:layoutObjectId",
                templateUrl: "app/spaces/tickets/tickets.html",
                data: { pageTitle: "test" },
                controller: "spacesTicketsCtrl",
                controllerAs: "ct",
                params: {
                    'layoutObjectId': '0',
                    'planogramLevelItemId': '0'
                },
            })
            .state("spaces.ticketsTypes", {
                url: "/ticketsTypes",
                templateUrl: "app/spaces/ticketsTypes/ticketsTypes.html",
                data: { pageTitle: "test" },
                controller: "spacesTicketsTypesCtrl",
                controllerAs: "ct"
            })
            .state("spaces.editTicketType", {
                url: "/ticketType/:ticketTypeId",
                templateUrl: "app/spaces/ticketsTypes/editTicketType.html",
                data: { pageTitle: "test" },
                controller: "EditTicketsTypesCtrl",
                controllerAs: "ct",
                params: {
                    ticketTypeId: '0',
                    //name: '',
                    //type: 1,
                    //needsDescription: false,
                }
            })
            .state("spaces.planograms", {
                url: "/planograms",
                templateUrl: "app/spaces/planograms/planograms.html",
                data: { pageTitle: "test" },
                controller: "spacesPlanogramsCtrl",
                controllerAs: "ct"
            })



        $urlRouterProvider.otherwise("/login");

        //$locationProvider.html5Mode(true);

    }

})();

