angular.module("prisma")
    .filter('statusFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters) return true;
                var rv = false;
                angular.forEach(filters.status, function (status) {
                    if (status.checked && status.id == 'All')
                        rv = true;
                    if (status.checked && status.id == 'Suggested' && price.isSuggested())
                        rv = true;
                    if (status.checked && status.id == 'Pending' && price.isPending())
                        rv = true;
                    if (status.checked && status.id == 'Endorsed' && price.isEndorsed())
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('priceListFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.priceLists.length == 0) return true;
                var rv = false;
                angular.forEach(filters.priceLists, function (pl) {
                    if (pl.code == price.priceListCode)
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('regionFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.regions.length == 0) return true;
                var rv = false;
                angular.forEach(filters.regions, function (region) {
                    if (region.regionId == price.regionId)
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('brandFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.brands.length == 0) return true;
                var rv = false;
                angular.forEach(filters.brands, function (brand) {
                    if (brand.brandId == price.brandId)
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('marketFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.markets.length == 0) return true;
                var rv = false;
                angular.forEach(filters.markets, function (market) {
                    if (market.marketId == price.marketId)
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('departmentFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.departments.length == 0) return true;

                for (var i = 0; i < filters.departments.length; i++)  {
                    var departmentWithZoning = filters.zoning.find(function (x) {
                        return x.id == filters.departments[i].departmentId;
                    });

                    for (var j = 0; j < departmentWithZoning.children.length; j++) {
                        if (departmentWithZoning.children[j].id == price.priceListId) {
                            return true;
                        }
                    }                   
                }
                return false;
            });

        };
    })
    .filter('sectorFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.sectors.length == 0) return true;

                for (var i = 0; i < filters.sectors.length; i++) {
                    //busco la ciudad del sector
                    var cityOfSector = null;
                    angular.forEach(filters.zoning, function (department) {
                        angular.forEach(department.children, function (city) {
                            var someSector = city.children.some(function (sector) { return sector.id == filters.sectors[i].sectorId });
                            if (someSector)
                                cityOfSector = city;
                        });
                        if (cityOfSector && cityOfSector.id == price.priceListId)
                            return true;
                    });
                }
                return false;
            });

        };
    })
    .filter('storeFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters || filters.stores.length == 0) return true;

                for (var i = 0; i < filters.stores.length; i++) {

                    //busco el sector de la tienda
                    var sectorOfStore = null;
                    angular.forEach(filters.zoning, function (department) {
                        angular.forEach(department.children, function (city) {
                            angular.forEach(city.children, function (sector) {
                                var someStore = sector.children.some(function (store) { return store.id == filters.stores[i].storeId });
                                if (someStore)
                                    sectorOfStore = sector;
                            });                            
                        });
                    });
                    //busco la ciudad del sector
                    var cityOfSector = null;
                    angular.forEach(filters.zoning, function (department) {
                        angular.forEach(department.children, function (city) {
                            var someSector = city.children.some(function (sector) { return sector.id == sectorOfStore.id });
                            if (someSector)
                                cityOfSector = city;
                        });
                        if (cityOfSector.id == price.priceListId)
                            return true;
                    });
                }
                return false;
            });

        };
    })
    .filter('priceTypeFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters) return true;
                var rv = false;
                angular.forEach(filters.priceTypes, function (pt) {
                    if (pt.checked && (pt.value == 0 /*Todos*/ || pt.value == price.priceType))
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('actionFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters) return true;
                var rv = false;
                angular.forEach(filters.actions, function (pt) {
                    var compareTo = price.newPrice ? price.newPrice : price.suggestedPrice || null;
                    if (pt.checked && (pt.value == 0 /*Todos*/
                      /*Bajas*/ || (pt.value == 1 && compareTo && compareTo < price._Price)
                      /*Subas*/ || (pt.value == 2 && compareTo && compareTo > price._Price)))
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('situationFilter', function () {
        return function (prices, filters) {
            return prices.filter(function (price) {
                if (!filters) return true;
                var rv = false;
                angular.forEach(filters.situations, function (sit) {
                    if (sit.checked && (sit.value == 0
                        || (sit.value == 1 && price.priceIsFirst == true)
                        || (sit.value == 2 && price.hasStrongDecreases()) //price.newPrice != null && price.minVariation != null && price.minVariation != 0 && price.priceDelta < price.minVariation)
                        || (sit.value == 3 && price.hasStrongIncreases()) //price.newPrice != null && price.maxVariation != null && price.maxVariation != 0 && price.priceDelta > price.maxVariation)
                        || (sit.value == 4 && price.hasLowMargin()) //price.newPrice != null && price.minMargin != null && price.minMargin != 0 && price.newGrossMargin < price.minMargin)
                        || (sit.value == 5 && price.hasHighMargin()) //price.newPrice != null && price.maxMargin != null && price.maxMargin != 0 && price.newGrossMargin > price.maxMargin)
                        || (sit.value == 6 && price.hasNotSignificantVariation()) // ((price.newPrice != null && price.significantVariation != null && price.significantVariation != 0 && Math.abs(price.priceDelta) < price.significantVariation) || (price.newPrice != null && price.significantVariationInMargin != null && price.significantVariationInMargin != 0 && Math.abs(price.newImpact) < price.significantVariationInMargin)))
                        || (sit.value == 7 && price.hasNotFollowedSuggestion()) // price.newPrice != null && price.newPrice != 0 && price.suggestedPrice != null && price.suggestedPrice != 0 && price.suggestedPrice != price.newPrice)
                        || (sit.value == 8 && price.hasOverlaps()) //price.overlappingPromotions && price.overlappingPromotions.length > 0)

                    ))
                        rv = true;
                });
                return rv;
            });

        };
    })
    .filter('search', function () {
        return function (prices, search) {
            if (search.length < 3)
                return prices;

            return prices.filter(function (price) {
                return (price.code && price.code.indexOf(search) > -1)
                    || (price.ean && price.ean.indexOf(search) > -1)
                    || (price.description && price.description.indexOf(search) > -1);                
            });

        };
    })
    .filter('startFrom', function () {
        return function (input, start) {
            start = +start; //parse to int
            return input.slice(start);
        }
    })
    .controller("PriceDecisionsCtrl", function ($scope, $rootScope, $window, $translate, $timeout, $interval, $state, $stateParams, $filter, $q, priceService, adminService, ngDialog, authService) {

        var dic = {};
        var numberColWidth = 100;
        var editableColWidth = 140;
        var lastAdded = null;
        var columnDefs = null;
        var changePriceNewImpact = 0;
        var selectedFilterForText = '';
        var self = this;
        var pageLoaded = false;

        self.showMarginInUSDperM3 = false; //TODO: obtener del company json

        self.companyCode = companyJson.Erp.Implementation;

        self.pageNumber = 0;
        self.pageSize = 20;
        self.busy = false;
        self.isEndorse = null;
        self.allPrices = [];
        self.allChecked = false;
        self.title = null;
        self.pricelists = [];
        self.markets = [];
        self.brands = [];
        self.regions = [];
        self.zoning = [];//zonificacion de larebaja (dpto, cdad, sector, pdv)
        self.departments = [];
        self.sectors = [];
        self.stores = [];
        self.loadingPriceSurveys = true;
        self.isEndorse = $stateParams.isEndorse ? JSON.parse($stateParams.isEndorse) : false;
        //Abm Etiquietas
        self.newGroupTag = "";
        self.newTagValue = "";
        self.tags = [];
        self.groupTag = [];
        self.suggestedImpact = 0;
        self.suggestedImpactShare = 0; //Impacto sobre total margen de la categoria
        self.newImpact = 0;
        self.newImpactShare = 0; //Impacto sobre total margen de la categoria
        self.numberOfNewPrices = 0;
        self.numberOfSuggestions = 0;
        self.numberOfSuggestionsAccepted = 0;
        self.percentageOfAcceptedSuggestions = 0;
        self.categoryMargin = 0;
        self.salesWithCurrentPrices = 0;
        self.salesWithNewPrices = 0;
        self.salesWithSuggestedPrices = 0;
        self.averagePriceChanges = 0;
        self.averagePriceNewVsSuggestedChanges = 0;
        self.priceDashboard = null;
        self.timerange = 'week';
        self.showChipsFilter = false;
        self.exchangeRate = 1;
        self.manualSearch = '';

        $scope.$on('$stateChangeStart', function (event) {
            var itemsToEndorse = getItemsToEndorse();
            var itemsToSave = getItemsToSave();
            var itemsToIgnore = getItemsToIgnore();
            //console.log('itemsToEndorse', itemsToEndorse);
            //console.log('itemsToSave', itemsToSave);
            //console.log('itemsToIgnore', itemsToIgnore);

            if (itemsToEndorse.length > 0
                || itemsToSave.length > 0
                || itemsToIgnore.length > 0
            ) {
                var answer = confirm(translations.AlertWillLoseChanges)
                if (!answer) {
                    event.preventDefault();
                }
            }


        });

        self.markAsReviewed = function (priceType, priceTypeName) {

            swal({
                title: translations.DoYouWishToIgnorePendingSuggestions,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.Yes,
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (ignorePendingSuggestions) {

                var alertMessage = '';
                if (priceType == 0)
                    alertMessage = ignorePendingSuggestions ? translations.PricesOfThisCategoryAsRevised : translations.PricesOfThisCategoryAsRevisedPartial;
                else if (priceType == 5)
                    alertMessage = ignorePendingSuggestions ? translations.CompetitionPricesOfThisCategoryAsRevised : translations.CompetitionPricesOfThisCategoryAsRevisedPartial;
                else if (priceType == 6)
                    alertMessage = ignorePendingSuggestions ? translations.NormalPricesOfThisCategoryAsRevised : translations.NormalPricesOfThisCategoryAsRevisedPartial;

                var swalConfig = {
                    title: translations.DiscardConfirmTitle,
                    text: alertMessage,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                };
                $timeout(function () {

                    swal(swalConfig, function () {
                        //1. Save changes
                        var result = saveNewPrices(false);

                        if (result && typeof result.then == 'function') { //si se hace el save la respuesta es un promise

                            result.then(function () {

                                //2. Mark as reviewed                        
                                markAsReviewed(priceType, ignorePendingSuggestions);

                            })
                                .catch(function (fallback) {
                                    console.log(fallback);
                                    swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                                });
                        }
                        else {

                            //2. Mark as reviewed                        
                            markAsReviewed(priceType, ignorePendingSuggestions);
                        }
                    });
                }, 500);
            });

        }

        self.canEndorse = function () {
            return authService.hasPermission('pricing_endorse');
        }

        //con esta funcion chequeo si el grupo de filtro tiene la opcion ALL y la desactivo si eligi� cualquier otro valor.
        self.onCheckboxFilterChanged = function (value, filters) {

            if (value.id == 'All' && value.checked == true) {
                filters.forEach(f => {
                    if (f.id != 'All')
                        f.checked = false;
                });
            }

            if (value.id != 'All' && value.checked == true) {
                filters.forEach(f => {
                    if (f.id == 'All')
                        f.checked = false;
                });
            }

            //self.filters.status.forEach(f => {
            //    if (f.checked == true && (f.id == 'Suggested' || f.id == 'Pending' || f.id == 'Endorsed')) {
            //        self.filters.actions[0].checked = false;
            //    }
            //});

            //self.filters.priceTypes.forEach(f => {
            //    if (f.checked == true && (f.id == 'Regular' || f.id == 'Competitive')) {
            //        self.filters.actions[0].checked = false;
            //    }
            //});

            //self.filters.actions.forEach(f => {
            //    if (f.checked == true && (f.id == 'Increase' || f.id == 'Decrease')) {
            //        self.filters.actions[0].checked = false; 
            //    }
            //});

        }

        function getItemsToIgnore() {

            var itemsToIgnore = [];

            angular.forEach(self.allPrices, function (price) {
                var item = price;
                if (item.ignored)
                    itemsToIgnore.push(item);
            });

            return itemsToIgnore
        }

        self.ignore = function () {

            swal({
                title: translations.DiscardConfirmTitle,
                text: translations.IgnoreSelectedSuggestions,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {

                    var itemsToIgnore = getItemsToIgnore();

                    if (itemsToIgnore.length > 0) {

                        priceService.decisions.ignoreSuggestions(itemsToIgnore)
                            .then(function (resp) {
                                if (resp) {
                                    swal(translations.ConfirmedSwal, translations.ConfirmedIgnoreSelectedSuggestions, "success");
                                    loadData();
                                }
                                else
                                    swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                            });
                    }
                    else {
                        $timeout(function () {
                            swal(translations.Attention, translations.NoChangesWereRecorded, "error");
                        }, 500);
                    }
                });
        }

        function getItemsToEndorse() {
            var itemsToEndorse = [];
            //recorro los items seleccionados
            angular.forEach(self.allPrices, function (price) {

                var item = price;

                //si el status es current o new
                if ((item.status == 0 || item.status == 1) && item.selected) {
                    //y tiene precio o fechas nuevas
                    if ((item.newPrice && item.newPrice != 0 && item.newPrice != item._Price)
                        || (item.newValidFrom && item.newValidFrom != item.validFrom)
                        || (item.newValidFrom && item.newValidFrom != item.validFrom)) {
                        itemsToEndorse.push(item);
                    }
                }

            });
            return itemsToEndorse;
        }

        self.endorse = function () {
            swal({
                title: translations.DiscardConfirmTitle,
                text: translations.EndorseTheModifiedPrices,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {
                    var itemsToEndorse = getItemsToEndorse();

                    var newPricesToEndorse = getNewPricesToSaveOrEndorse(itemsToEndorse, true);

                    if (itemsToEndorse.length > 0) {
                        priceService.decisions.endorsePrices(newPricesToEndorse).then(function () {
                            swal(translations.ConfirmedSwal, translations.ConfirmedEndorseTheSelectedPrices, "success");
                            loadData();

                        }).catch(function (fallback) {
                            console.log(fallback);
                            swal(translations.ErrorTitleSwal, translations.ErrorWhenWantingEndorsePrices, "error");
                        });
                    }
                    else {
                        $timeout(function () {
                            swal(translations.Attention, translations.NoChangesWereRecorded, "error");
                        }, 500);
                    }
                });
        }

        function getItemsToReject() {
            var pricesToReject = [];

            // rechazo solo los seleccionados.
            angular.forEach(self.allPrices, function (price) {
                var item = price;
                if (item.rejected)
                    pricesToReject.push(item);
            });

            return pricesToReject;
        }

        self.reject = function () {
            swal({
                title: translations.RejectionComment,
                text: translations.RejectionCommentSubtitle,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                //inputPlaceholder: ""
            },
                function (inputValue) {
                    if (inputValue === false)
                        return false;

                    var pricesToReject = [];

                    // rechazo solo los seleccionados.
                    angular.forEach(self.allPrices, function (price) {
                        var item = price;
                        if (item.rejected)
                            pricesToReject.push(item);
                    });

                    if (pricesToReject.length > 0) {
                        // Guardar cambios

                        var currentUrl = window.location.href;
                        priceService.decisions.rejectEndorse(pricesToReject, inputValue, currentUrl)
                            .then(function (resp) {
                                if (resp) {
                                    swal(translations.RejectedItems, translations.RejectedItemsSubtitle, "success");
                                    loadData();
                                }
                                else
                                    swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                            });
                    }
                });
        }

        self.openChatDialog = function (priceId) {
            var newScope = $scope.$new();
            newScope.showLoader = true;

            priceService.decisions.getChatForItem(priceId).then(function (data) {
                newScope.showLoader = false;

                newScope.currentDate = new Date();
                newScope.chats = [];
                newScope.isSaving = false;
                newScope.newChat = {
                    id: 0,
                    priceId: priceId,
                    message: "",
                    pictureUrl: "defaultUser.jpg", //TODO: Reemplazar por el current userPicture
                    sendDate: new Date()
                };

                if (data && data.length > 0)
                    newScope.chats = data;

                newScope.saveMessage = function () {
                    newScope.isSaving = true;
                    priceService.decisions.saveChatForItem(newScope.newChat).then(function (resp) {
                        if (resp != null) {
                            newScope.chats.push(resp);
                            newScope.newChat.message = "";
                            newScope.isSaving = false;
                        }
                        else {
                            newScope.isSaving = true;
                        }
                    });
                }
            });

            ngDialog.open({
                template: 'chatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: newScope
            });
        }

        self.showNewTagModal = function () {
            ngDialog.open({
                template: 'modal-newTag',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-500',
                scope: $scope
            });
        }


        self.filterByText = function () {
            var eInput = document.querySelector('#searchGridInput');
            if (eInput) {

                if ((eInput.value.length > 2 && selectedFilterForText != eInput.value) || (eInput.value == '' && selectedFilterForText != '')) {
                    selectedFilterForText = eInput.value;
                }
            }
        }

        self.dateEditor = function (params) {
            if (self.isEndorse) {
                return $rootScope.dateRenderer(params);
            }
            else {
                return $rootScope.dateEditor(params);
            }
        }

        self.updateDates = function (isValidFrom) {
            swal({
                title: translations.UpdateDates,
                text: isValidFrom ? translations.UpdateDatesSubtitleOp1 : translations.UpdateDatesSubtitleOp2,
                type: 'input',
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (inputValue) {

                    if (inputValue === false)
                        return false;

                    var timestamp = Date.parse(inputValue)

                    if (isNaN(timestamp) == false) {
                        var d = new Date(timestamp);

                        //TODO: Ver como Ponerle un DatePicker al Sweet Alert

                        angular.forEach(getFilteredPrices(), function (p) {

                            if (p.newPrice != null && p.newPrice != '' && p.newPrice != 0) {
                                if (isValidFrom)
                                    p.newValidFrom = d;
                                else
                                    p.newValidTo = d;
                                p.dirty = true;

                            }
                        });
                        setTimeout(function () {
                            swal(translations.Success, '', 'success');
                        }, 1000);
                        ngDialog.close();
                    }


                });
        }

        self.showItemInDashboard = function (itemId, priceListId) {
            $state.go('home.itemDashboard', { 'itemId': itemId, 'priceListId': priceListId });
        }


        /***Nuevas Funciones****/

        self.selectPrice = function (p) {
            if (self.selectedPrice && self.selectedPrice.priceId === p.priceId) {
                self.selectedPrice = null;
            }
            else {
                self.selectedPrice = p;
            }

            self.toggleTabsRow(p);
        }
        

        self.toggleTabsRow = function (p) {

            var speed = 200;

            if (self.selectedPrice) {
                $('#' + p.priceId).attr('hidden', false).show();
                $('#' + p.priceId)
                    .children('td')
                    .animate({ paddingTop: 8, paddingBottom: 8 }, speed)
                    .children()
                    .slideDown(speed);
            }
            else {
                $('#' + p.priceId)
                    .children('td')
                    .animate({ paddingTop: 0, paddingBottom: 0 }, speed)
                    .children()
                    .slideUp(speed, function () { $('#' + p.priceId).attr('hidden', true).hide(); });
            }
        }

        self.toggleSuggestionTab = function (price) {
            if (price) {

                if (!price.priceSurveyTypes) {
                    price.priceSurveys = [];
                    price.priceSurveyTypes = ['', translations.Basket, 'EXTRA', 'PRICEINDEX', translations.Promotion, translations.Own];
                    //priceService.pricesurveys.onlyActive(price.itemId, price.priceListId, false)
                    //    .then(function (priceSurveys) {
                    //        price.priceSurveys = priceSurveys;
                    //    });
                    if (price.priceSuggestionId)
                        priceService.pricesurveys.getByPriceSuggestionId(price.priceSuggestionId)
                            .then(function (priceSurveys) {
                                price.priceSurveys = priceSurveys;
                            });
                }

            }
        }

        self.toggleMarginsAndCostsTab = function (price, active) {
            if (!price) return;
            price.marginTab = active;
        }


        self.toggleNotificationsTab = function (price, active) {
            if (!price) return;
            price.notifications = active;
        }

        self.isLoadingAuditTrails = false;
        self.toggleAuditTrailTab = function (price) {
            if (!price) return;

            if (!price.auditTrail) {
                self.isLoadingAuditTrails = true;
                adminService.auditTrail.getByObject(price.priceId, 'Price', 60).then(function (data) {
                    self.isLoadingAuditTrails = false;
                    price.auditTrail = data;
                });;
            }
        }

        self.toggleCompetitonTab = function (price, active) {
            if (!price) return;

            //No es necesario tener competitorsGroupPrices antes de mostrar el componente.

            //if (price.competitorsGroupPrices) {
            //    price.viewMap = active;
            //} else {

            //    //console.log('PRICESSSSS', price);
            //    //console.log('price.priceListId', price.priceListId);

            //    priceService.decisions.getCompetitorsGroupPrices(price.itemId, price.priceListId).then(function (competitorPrices) {
            //        //console.log('competitorPrices', competitorPrices);
            //        price.competitorsGroupPrices = competitorPrices;
            //        price.viewMap = active;

            //    });
            //}

            price.viewMap = active;
        }

        self.toggleKpiItemView = function (price, active) {
            if (!price) return;
            price.kpiItemView = active;
        }

        self.acceptAll = function () {
            var filteredPrices = getFilteredPrices();

            for (var i = 0; i < filteredPrices.length; i++) {

                var price = filteredPrices[i];

                if (price.status != 0 || price.suggestionAccepted == true) continue;

                if (price.suggestedPrice) {
                    price.changePrice(price.suggestedPrice);
                    price.suggestionAccepted = true;
                } else if (price.suggestedPrice) {
                    self.numberOfSuggestionsAccepted++;
                    self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                }
            }
        }

        function getFilteredPrices() {
            var filteredPrices = $filter('statusFilter')(self.allPrices, self.appliedFilters);
            filteredPrices = $filter('priceListFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('priceTypeFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('situationFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('actionFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('regionFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('brandFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('marketFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('departmentFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('sectorFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('storeFilter')(filteredPrices, self.appliedFilters);
            filteredPrices = $filter('search')(filteredPrices, self.manualSearch);

            return filteredPrices;
        }

        function getCheckedPrices() {
            return self.allPrices.filter(p => p.isChecked === true);
        }

        self.endorseAll = function () {
            var filteredPrices = getFilteredPrices();

            for (var i = 0; i < filteredPrices.length; i++) {
                var price = filteredPrices[i];

                if (price.status != 1) continue;

                price.selected = true;
                price.rejected = false;
            }
        }


    //var previousLookupText = '';
    //$scope.$watch('ct.manualSearch', function (newVal, oldVal) {
    //    if (newVal) {

    //        if (newVal && newVal != oldVal && newVal != previousLookupText) {
    //            previousLookupText = newVal;
    //            self.pageNumber = 0; //Vuelvo a la pagina 1
    //            self.totalFilteredPrices = _.size(getFilteredPrices()); //Recalculo el total de precios que quedaron
    //        }
    //    }
    //    else {
    //        self.pageNumber = 0; //Vuelvo a la pagina 1
    //        self.totalFilteredPrices = _.size(getFilteredPrices()); //Recalculo el total de precios que quedaron
    //    }
    //}, true);

        self.onSearch = function () {
            if (self.manualSearch.length > 2) {
                self.pageNumber = 0; //Vuelvo a la pagina 1
            }
                self.totalFilteredPrices = _.size(getFilteredPrices()); //Recalculo el total de precios que quedaron
        }


        self.openPriceCopyDialog = function () {
            self.priceTypeTarget = null;
            self.priceTypeSource = null;
            self.cancel = function () {
                ngDialog.close();
            }

            self.onChangePriceTypeTarget = function () {
                self.priceTypeSource = self.priceTypeTarget == 5 ? 6 : 5;
            }

            self.copyPrices = function () {
                if (!self.priceTypeSource || !self.priceTypeTarget || !self.updateType || self.updateValue == null || self.updateValue == undefined) {
                    swal(translations.ValidationSwalSubtitle, '', 'error');
                    return;
                }
                var swalConfig = {
                    title: translations.PriceUpdateConfirmTitle,
                    text: translations.PriceUpdateConfirmText,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                }

                swal(swalConfig, function () {

                    angular.forEach(getFilteredPrices(), function (p) {

                        let priceSource = self.allPrices.filter(function (x) {
                            return x.itemId === p.itemId
                                && x.salesUnitOfMeasureId === p.salesUnitOfMeasureId
                                && x.priceId != p.priceId
                                && x.priceListId === p.priceListId
                                && x.priceType === self.priceTypeSource;
                        })[0];


                        let newPrice = null;
                        if (priceSource) {
                            if (self.updateType == '$')
                                newPrice = priceSource._Price + self.updateValue;
                            else
                                newPrice = priceSource._Price + (priceSource._Price * self.updateValue / 100);


                            p.changePrice(newPrice);
                        }

                    });

                    setTimeout(function () {
                        swal(translations.Success, '', 'success');
                    }, 1000);
                    ngDialog.close();
                });
            }

            ngDialog.open({
                template: 'priceCopyDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: $scope
            });
        }

        self.openPriceUpdateDialog = function () {
            self.priceUpdateStrategy = "1";
            self.priceUpdateOperator = "%";
            self.updateValue = null;

            self.onChangeUpdateStrategy = function () {
                if (self.priceUpdateStrategy == "1")
                    self.priceUpdateOperator = "%";
                //if (self.priceUpdateStrategy == "2")
                //    self.priceUpdateOperator = "USDM3";
                if (self.priceUpdateStrategy == "3")
                    self.priceUpdateOperator = "$";
            }


            self.cancel = function () {
                ngDialog.close();
            }

            self.update = function () {
                if (!self.priceUpdateStrategy || !self.updateValue) {
                    swal(translations.ValidationSwalSubtitle, '', 'error');
                    return;
                }
                var swalConfig = {
                    title: translations.PriceUpdateConfirmTitle,
                    text: translations.PriceUpdateConfirmText,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                }

                swal(swalConfig, function () {

                    getCheckedPrices().forEach(p => {

                        //actualizacion masiva por variacion porcentual del precio
                        if (self.priceUpdateStrategy == "1") {

                            if (self.priceUpdateOperator == "%") {
                                newPrice = p._Price + (p._Price * self.updateValue / 100);
                            }

                            if (self.priceUpdateOperator == "$") {
                                newPrice = p._Price + self.updateValue;
                            }
                        }

                        //actualizacion masiva por margen objetivo
                        if (self.priceUpdateStrategy == "2") {

                            var cost = p.newCost ? p.newCost : p.cost;
                            var tax = p.newTax ? p.newTax : p.tax;
                            var taxRate = p.newTaxRate ? p.newTaxRate : p.taxRate;
                            var newprice = null;

                            if (self.priceUpdateOperator == "USDM3") {

                                var marginInUSDperM3 = self.updateValue;
                                var targetMarginAmount = (marginInUSDperM3 * self.exchangeRate.fxRate) / 1000;

                                newPrice = (cost + targetMarginAmount) * (1 + taxRate) + tax;
                            }

                            if (self.priceUpdateOperator == "%") {

                                var targetMargin = self.updateValue / 100;

                                if (targetMargin != 1) {
                                    newPrice = (((cost + tax) / (1 - targetMargin)) - tax) * (1 + taxRate) + tax;
                                }
                                else
                                    newPrice = 0;
                            }
                        }

                        //actualizacion masiva a precio fijo
                        if (self.priceUpdateStrategy == "3") {
                            newPrice = self.updateValue;
                        }


                        //actualizo el precio
                        if (newPrice) {
                            p.changePrice(newPrice);
                        }
                        


                    });

                    setTimeout(function () {
                        swal(translations.Success, '', 'success');
                    }, 1000);
                    ngDialog.close();
                });
            }

            ngDialog.open({
                template: 'priceUpdateDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: $scope
            });
        }

        self.openPriceSimulationDialog = function () {
            self.priceUpdateStrategy = "1";
            self.priceUpdateOperator = "%";
            self.updateValue = null;

            self.onChangeUpdateStrategy = function () {
                if (self.priceUpdateStrategy == "1")
                    self.priceUpdateOperator = "%";
                //if (self.priceUpdateStrategy == "2")
                //    self.priceUpdateOperator = "USDM3";
                if (self.priceUpdateStrategy == "3")
                    self.priceUpdateOperator = "$";
            }

            self.cancel = function () {
                ngDialog.close();
            }

            self.simulatePriceUpdate = function () {
                if (!self.priceUpdateStrategy || !self.updateValue) {
                    swal(translations.ValidationSwalSubtitle, '', 'error');
                    return;
                }

                getCheckedPrices().forEach(p => {

                    //actualizacion masiva por variacion porcentual del precio
                    if (self.priceUpdateStrategy == "1") {

                        if (self.priceUpdateOperator == "%") {
                            newPrice = p._Price + (p._Price * self.updateValue / 100);
                        }

                        if (self.priceUpdateOperator == "$") {
                            newPrice = p._Price + self.updateValue;
                        }
                    }

                    //actualizacion masiva por margen objetivo
                    if (self.priceUpdateStrategy == "2") {

                        var cost = p.newCost ? p.newCost : p.cost;
                        var tax = p.newTax ? p.newTax : p.tax;
                        var taxRate = p.newTaxRate ? p.newTaxRate : p.taxRate;
                        var newprice = null;

                        if (self.priceUpdateOperator == "USDM3") {

                            var marginInUSDperM3 = self.updateValue;
                            var targetMarginAmount = (marginInUSDperM3 * self.exchangeRate.fxRate) / 1000;

                            newPrice = (cost + targetMarginAmount) * (1 + taxRate) + tax;
                        }

                        if (self.priceUpdateOperator == "%") {

                            var targetMargin = self.updateValue / 100;

                            if (targetMargin != 1) {
                                newPrice = (((cost + tax) / (1 - targetMargin)) - tax) * (1 + taxRate) + tax;
                            }
                            else
                                newPrice = 0;
                        }                        
                    }

                    //actualizacion masiva a precio fijo
                    if (self.priceUpdateStrategy == "3") {
                        newPrice = self.updateValue;
                    }


                    //actualizo el precio
                    if (newPrice) {
                        p.changePrice(newPrice);
                        p.isSimulated = true;
                    }

                    self.isSimulationActive = self.allPrices.filter(p => p.isSimulated).length > 0;

                });

                swal(translations.Success, '', 'success');
                ngDialog.close();

            }

            ngDialog.open({
                template: 'priceSimulationDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-width-300',
                scope: $scope
            });
        }

        self.cancelPriceSimulation = function () {
            self.allPrices.forEach(p => p.isSimulated = false);
        }

        self.setEndorsed = function (item) {
            item.selected = true;
            item.rejected = false;
        }

        self.unsetEndorsed = function (item) {
            item.selected = false;
        }

        self.openStateActions = function (price) {

            if (price.popOverIsOpen == true) {
                price.popOverIsOpen = false;
            }

            else {
                price.openTimer = $timeout(function () {
                    closeAllPopovers();
                    price.popOverIsOpen = true;
                }, 250);
            }
        }

        $window.document.onclick = function () {
            $scope.$apply(function () {
                closeAllPopovers();
            });
        }


        self.saveAll = function () {

            var anySimilationActive = self.allPrices.filter(p => p.isSimulated).length > 0;

            if (anySimilationActive) {
                swal(translations.Pr_Decisions_SimulationWarningOnSave_Title, translations.Pr_Decisions_SimulationWarningOnSave_Desc, 'warning');
                return;
            }


            var toSave = getItemsToSave();
            var toIgnore = getItemsToIgnore();
            var toEndorse = getItemsToEndorse();
            var toReject = getItemsToReject();

            if (toSave.length == 0 && toIgnore.length == 0 && toEndorse.length == 0 && toReject.length == 0) {
                swal(translations.Attention, translations.NoChangesWereRecorded, "warning");
                return;
            }

            var message = ian.format(translations.TheFollowingChangesWillBeSaved);
            if (toSave.length > 0)
                message += toSave.length + translations.PriceChangesOp;
            if (toIgnore.length > 0)
                message += toIgnore.length + translations.IgnoredSuggestionsOp;
            if (toEndorse.length > 0)
                message += toEndorse.length + translations.EndorsedPricesOp;
            if (toReject.length > 0)
                message += toReject.length + translations.RejectedPricesOp;

            //saco la ultima coma
            message = message.replace(/,\s*$/, "");


            swal({
                title: translations.DiscardConfirmTitle,
                text: message,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {

                    var promises = [];

                    //prices to save
                    if (toSave.length > 0) {
                        var newPricesToSave = getNewPricesToSaveOrEndorse(toSave, false);
                        promises.push(priceService.decisions.saveNewPrices($stateParams.categoryId, newPricesToSave));
                    }

                    //prices to ignore
                    if (toIgnore.length > 0) {
                        promises.push(priceService.decisions.ignoreSuggestions(toIgnore));
                    }

                    //prices to endorse
                    if (toEndorse.length > 0) {
                        var newPricesToEndorse = getNewPricesToSaveOrEndorse(toEndorse, true);
                        promises.push(priceService.decisions.endorsePrices(newPricesToEndorse));
                    }

                    //prices to reject
                    if (toReject.length > 0) {
                        var reason = toReject[0].rejectedReason;
                        promises.push(priceService.decisions.rejectEndorse(toReject, reason, window.location.href));
                    }


                    $q.all(promises).then(function (data) {
                        swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                        loadData();
                    }, function (error) {
                        swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                    });



                });
        }

        self.onChangeItemTags = function (price) {
            adminService.items.saveItemTags(price.itemTags.map(x => x.id), price.itemId);
        }

        self.saveNewTag = function () {
            if (self.newGroupTag == "" || self.newTagValue == "") {
                swal(translations.ValidationSwalSubtitle, '', 'error');
                return;
            }
            adminService.tags.saveTag({ group: self.newGroupTag, name: self.newTagValue })
                .then(function (response) {
                    if (response.id) {
                        self.tags.push(response);
                        swal(translations.HasBeenSuccessfullySavedSwal, '', 'success');
                    }
                    else swal(translations.ErrorTitleSwal, '', 'error');
                }, function () {
                    swal(translations.ErrorTitleSwal, '', 'error');
                });
            ngDialog.close();
        }

        self.saveNewGroupTag = function () {
            swal({
                title: translations.NewTagGroup,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                cancelButtonText: translations.CancelSwal,
            }, function (inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError(translations.ValidationSwalSubtitle);
                    return false
                }
                self.newGroupTag = inputValue;
                self.groupTag.push(inputValue);
                $timeout(function () {
                    swal(translations.HasBeenSuccessfullySavedSwal, "", 'success');
                }, 100);
            });
        }

        self.changeSuggestedPrice = function (price) {

            if (!price.allowManualChange) {
                price.allowManualChange = true;
                price.newPriceByUser = price._Price; //price.suggestedPrice ? price.suggestedPrice :
            }
            else {
                price.allowManualChange = false;
                price.newPriceByUser = 0;
            }

        }

        self.changePriceAndCloseEditor = function (p) {
            p.changePrice(p.newPriceByUser);
            p.allowManualChange = false;
        }

        self.saveChangedPrices = function () {

            var swalConfig = {
                title: translations.DiscardConfirmTitle,
                text: translations.AreYouSureSwalSubtitle,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }

            swal(swalConfig, function () {

                var result = saveNewPrices(true);

                if (result && typeof result.then == 'function') { //if response is a promise
                    result.then(function () {
                        swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                        loadData();
                    })
                        .catch(function (fallback) {
                            console.log(fallback);
                            swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                        });
                }
            });
        }

        self.orderBy = function (price) {
            //return Math.abs(price.suggestedImpact);
            return price.index;
        }

        self.toggleCheckAll = function () {
            getFilteredPrices().forEach(p => p.isChecked = self.allChecked);
            self.onCheckboxChanged();
        }

        self.clearSelectedFilter = function (type, id) {
            if (type == 'priceList') {
                var removeIndex = -1;
                for (var i = 0; i < self.filters.priceLists.length; i++) {
                    if (self.filters.priceLists[i].id === id) {
                        removeIndex = i;
                    }
                }

                if (removeIndex > -1) {
                    self.filters.priceLists.splice(removeIndex, 1);
                    self.applyFilters();

                }
            }
        }

        self.closeSelectedFilters = function () {
            if (self.filters.priceLists.length <= 0) {
                return 'closeFilters';
            }
        }

        self.cleanAllFilter = function () {
            if (self.filters.priceLists.length > 0) {
                self.filters.priceLists = [];
            }

            self.applyFilters();
        }

        self.applyPricingZoneFilter = function (id, code, name) {
            self.filters.priceLists = [];
            self.filters.priceLists.push({ id: id, code: code, name: name });
            self.applyFilters();
        }

        self.applyFilters = function () {
            //console.log('filters', self.filters);
            self.appliedFilters = angular.copy(self.filters);

            updateFilterCounters();
            self.closeFilters();

            if (self.filters.priceLists.length > 0) {
                self.showChipsFilter = true;
            }
            else {
                $timeout(function () {
                    self.showChipsFilter = false;
                }, 200);
            }
        }

        self.getAppliedFilters = function () {
            return self.appliedFilters;
        }

        self.toggleChilds = function (parentPrice) {

            parentPrice.showChilds = !parentPrice.showChilds;

            angular.forEach(self.allPrices, function (p) {
                if (p.parentPriceId === parentPrice.priceId)
                    p.showMe = !p.showMe;
            });
        }


        function updateFilterCounters() {

            //dynamic filter counting
            var filteredPrices = getFilteredPrices();

            //Select All Filters
            self.filters.status[0].count = filteredPrices.length;
            self.filters.priceTypes[0].count = filteredPrices.length;
            self.filters.actions[0].count = filteredPrices.length;
            self.filters.situations[0].count = filteredPrices.length;

            self.filters.status[1].count = 0;
            self.filters.status[2].count = 0;
            self.filters.status[3].count = 0;

            self.filters.priceTypes[1].count = 0;
            self.filters.priceTypes[2].count = 0;

            self.filters.actions[1].count = 0;
            self.filters.actions[2].count = 0;

            self.filters.situations[1].count = 0;
            self.filters.situations[2].count = 0;
            self.filters.situations[3].count = 0;
            self.filters.situations[4].count = 0;
            self.filters.situations[5].count = 0;
            self.filters.situations[6].count = 0;
            self.filters.situations[7].count = 0;
            self.filters.situations[8].count = 0;

            //do counting
            angular.forEach(filteredPrices, function (price) {
                //status
                if (price.isSuggested())
                    self.filters.status[1].count++;
                else if (price.isPending())
                    self.filters.status[2].count++;
                else if (price.isEndorsed())
                    self.filters.status[3].count++;

                //price type
                if (price.priceType == 6)
                    self.filters.priceTypes[1].count++;
                else if (price.priceType == 5)
                    self.filters.priceTypes[2].count++;

                //actions
                var compareTo = price.newPrice ? price.newPrice : price.suggestedPrice || null;
                if (compareTo) {
                    if (price._Price > compareTo) //Bajas
                        self.filters.actions[1].count++;
                    else if (price._Price < compareTo) //Subas
                        self.filters.actions[2].count++;
                }
                
                //situations
                if (price.priceIsFirst == true)
                    self.filters.situations[1].count++;
                if (price.hasStrongDecreases())
                    self.filters.situations[2].count++;
                if (price.hasStrongIncreases())
                    self.filters.situations[3].count++;
                if (price.hasLowMargin())
                    self.filters.situations[4].count++;
                if (price.hasHighMargin())
                    self.filters.situations[5].count++;
                if (price.hasNotSignificantVariation())
                    self.filters.situations[6].count++;
                if (price.hasNotFollowedSuggestion())
                    self.filters.situations[7].count++;
                if (price.hasOverlaps())
                    self.filters.situations[8].count++;

            });

            self.totalFilteredPrices = _.size(filteredPrices);
        }

        function extendPriceObjects(prices) {

            //agregar todas las funciones y propiedades extra que se 
            //necesiten a este objeto para extender la funcionalidad de los dtos
            //entregados por la API Rest.
            var extendedPrice = {

                isChecked: false,
                isExperimental: false,
                popOverIsOpen: false,
                suggestionAccepted: false,
                priceChangeCount: 0,
                getPrice: function () {
                    if (this.newPrice)
                        return this.newPrice;

                    return this._Price;
                },
                getMargin: function () {
                    if (this.newGrossMargin)
                        return this.newGrossMargin;

                    return this.grossMargin;
                },
                getMarginAbs: function () {
                    if (this.newGrossMarginAbs)
                        return this.newGrossMarginAbs;

                    return this.grossMarginAbs;
                },
                getStatusIcon: function () {
                    if (this.status == 0) {
                        if (this.suggestedPrice)
                            if (this.ignored)
                                return '/assets/images/prices/popover/iconoSugerencia-ignorada.png';
                            else
                                return '/assets/images/prices/popover/iconoSugerencia.png';
                        else
                            return '/assets/images/prices/popover/iconoActual.png';
                    }
                    if (this.status == 1) {
                        if (this.rejected)
                            return '/assets/images/prices/popover/iconoPendiente-rechazado.png';
                        else
                            return '/assets/images/prices/popover/iconoPendiente.png';
                    }
                    if (this.status == 2) {
                        return '/assets/images/prices/popover/iconoEndosado.png';
                    }
                },
                getPopoverIcon: function () {
                    if (this.status == 0) {
                        if (this.suggestedPrice)
                            return '/assets/images/prices/popover/iconoSugerenciaPopover.png';
                        else
                            return '/assets/images/prices/popover/iconoActualPopover.png';
                    }
                    if (this.status == 1) {
                        return '/assets/images/prices/popover/iconoPendientePopover.png';
                    }
                    if (this.status == 2) {
                        return '/assets/images/prices/popover/iconoEndosadoPopover.png';
                    }
                },
                getStatusColor: function () {
                    if (this.status == 0) { //Si es un precio actual
                        if (this.suggestedPrice) {
                            if (this.suggestionAccepted || this.ignored) //Si tiene sugerencia y se acepto
                                return '#FCD9B1';
                            else {
                                if (this.newPriceByUser != this._Price && this.allowManualChange == false) //Si tiene sugerencia, pero se cambio a un precio manual
                                    return '#FCD9B1';
                                else //Si tiene sugerencia, pero aun no se acepto
                                    return '#f8ac5a';
                            }
                        }
                        else //Si no tiene sugerencia, es un actual
                        {
                            if (this.allowManualChange == false) //Si se se cambio el precio actual a mano
                                return '#ACD7F2';
                            else //Si no se cambio nada, es actual
                                return '#1d84c6';
                        }
                    }
                    if (this.status == 1) { //Si es nuevo (para endosar)
                        if (this.selected || this.rejected) //Si ya lo endose pero no guarde
                            return '#BAECED';
                        else
                            return '#24c6c8';//Sigue pendiente de endoso
                    }
                    if (this.status == 2) { //Si ya esta endosado
                        return '#1bb394';
                    }
                },
                priceTypeDesc: function () {
                    //console.log(priceService.priceTypes[this.priceType + 1]);


                    //return priceService.priceTypes[this.priceType + 1];
                },
                getStatusTooltip: function () {
                    if (this.status == 0) {
                        if (this.suggestedPrice)
                            return translations.SuggestionTooltip;
                        else
                            return translations.CurrentTooltip;
                    }
                    if (this.status == 1) {
                        return translations.NewTooltip;
                    }
                    if (this.status == 2) {
                        return translations.EndorsedTooltip;
                    }

                },
                getStatusPopoverTitle: function () {
                    if (this.status == 0) {
                        if (this.suggestedPrice)
                            return translations.Suggested;
                        else
                            return translations.Current;
                    }
                    if (this.status == 1) {
                        return translations.Pending;
                    }
                    if (this.status == 2) {
                        return translations.Endorsed;
                    }

                },
                getStatusPopoverSubtitle: function () {
                    if (this.status == 0) {
                        if (this.suggestedPrice)
                            return translations.NewPriceSuggestion;
                        else
                            return translations.ERPPrice;
                    }
                    if (this.status == 1) {
                        return translations.PriceNeedsEndorsement;
                    }
                    if (this.status == 2) {
                        return translations.ReadyToInformToERP;
                    }

                },
                rejectPrice: function () {
                    var thisPrice = this;
                    if (thisPrice.status = 1)//Endosado
                    {
                        swal({
                            title: translations.RejectionComment,
                            text: translations.RejectionCommentSubtitle,
                            type: "input",
                            showCancelButton: true,
                            closeOnConfirm: true,
                            animation: "slide-from-top",
                            inputPlaceholder: ""
                        },
                            function (inputValue) {
                                if (inputValue === false)
                                    return false;

                                thisPrice.rejectedReason = inputValue;
                                thisPrice.selected = false;
                                thisPrice.rejected = !this.rejected;

                            });
                    }
                },
                ignorePrice: function () {
                    this.ignored = !this.ignored;
                },
                toggleSuggestion: function (accept) {
                    if (accept) {
                        //if (this.suggestedPrice && (this.newPrice == null || this.newPrice == '' || this.newPrice == 0)) {
                        if (this.suggestedPrice) {
                            this.changePrice(this.suggestedPrice);
                            this.suggestionAccepted = true;
                        } else if (this.suggestedPrice) {
                            self.numberOfSuggestionsAccepted++;
                            self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                        }
                    }
                    else {
                        if (!this.hasNewPriceFromDb) {
                            this.changePrice(null);
                            this.suggestionAccepted = false;
                        }
                        else if (this.suggestedPrice) {
                            self.numberOfSuggestionsAccepted--;
                            self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                        }
                    }
                },
                canEditPrice: function () {
                    if (this.status == 0) {
                        if (this.allowManualChange)
                            return true;
                        else
                            return false;
                    }
                    if (this.status == 1) {
                        return false;
                    }
                    if (this.status == 2) {
                        return false;
                    }
                },
                getTitle: function () {
                    var title = this.rejectedPrice != null ? translations.NewPriceHasBeenRejected + this.rejectedPrice + translations.GoToTheAuditTab : '';
                    return title;
                },
                changePrice: function (newPrice) {

                    if (this._Price != null) {
                        if (newPrice != null && newPrice != '' && newPrice != 0) {
                            //guardo el precio anterior para no incrementar el conta=dor de cambios si es una modificacion del mismo precio
                            var oldNewPrice = this.newPrice;
                            //Chequeo que est� cumpliendo con los lineamientos de margen
                            var cost = this.newCost != null ? this.newCost : this.cost;
                            var tax = this.newTax != null ? this.newTax : this.tax;
                            var taxRate = this.newTaxRate != null ? this.newTaxRate : this.taxRate;
                            this.newGrossMarginAbs = (cost + tax) != 0 ? (((newPrice - tax) / (1 + taxRate)) - cost) : 0;

                            //Actualizo variables relacionadas al nuevo precio
                            this.newGrossMargin = (cost + tax) != 0 ? (((newPrice - tax) / (1 + taxRate)) - cost) / (((newPrice - tax) / (1 + taxRate)) + tax) : null;
                            changePriceNewImpact = (this.newGrossMarginAbs - this.grossMarginAbs) * this.units;
                            this.newPrice = newPrice;
                            this.priceDelta = (newPrice / this._Price) - 1;
                            this.competitorsPriceVsNew = this.competitorsPrice != 0 && this.competitorsPrice != null ? (newPrice / this.competitorsPrice) - 1 : 0;
                            this.newSales = newPrice * this.units;

                            //Si la sugerencia tiene fechas de vigencia y el campo de nuevas fechas est� en nulo, entonces seteo las fechas de la sugerencia
                            if (this.newValidFrom == null && this.priceSuggestionValidFrom != null)
                                this.newValidFrom = this.priceSuggestionValidFrom;

                            if (this.newValidTo == null && this.priceSuggestionValidTo != null)
                                this.newValidTo = this.priceSuggestionValidTo;

                            //Chequeo si esta fuera de Guidelines
                            var outOfGuidelines = false;
                            if (this._Price != null) {
                                if (newPrice != null && newPrice != '') {
                                    if (this.minMargin != 0 && this.newGrossMargin < this.minMargin) {
                                        outOfGuidelines = true;
                                        //marginGuidelinesAlert("El nuevo margen: " + $rootScope.toPercentage(this.newGrossMargin) + " es menor al margen minimo: " + $rootScope.toPercentage(this.minMargin));
                                    }
                                    if (this.maxMargin != 0 && this.newGrossMargin > this.maxMargin) {
                                        outOfGuidelines = true;
                                        //marginGuidelinesAlert("El nuevo margen: " + $rootScope.toPercentage(this.newGrossMargin) + " es mayor al margen maximo: " + $rootScope.toPercentage(this.maxMargin));
                                    }
                                }
                            }
                            this.outOfGuidelines = true;

                            //Hago esto para evitar sumarlo 2 veces
                            if (this.newPrice != null && this.newPrice != '' && this.newPrice != 0) {
                                if (oldNewPrice == null)
                                    self.numberOfNewPrices++;

                                self.salesWithNewPrices += (newPrice - this._Price) * this.units;
                            }
                            else {
                                self.salesWithNewPrices += (newPrice - this.newPrice) * this.units;
                            }

                            self.averagePriceChanges = Math.round((self.salesWithNewPrices / self.salesWithCurrentPrices - 1) * 10000) / 10000;
                            self.averagePriceNewVsSuggestedChanges = Math.round((self.salesWithNewPrices / self.salesWithSuggestedPrices - 1) * 10000) / 10000;

                            this.dirty = true;

                            //Cambios en los KPIs del Header
                            self.newImpact += changePriceNewImpact - this.newImpact;
                            self.newImpactShare = self.newImpact / self.categoryMargin; //Impacto sobre total margen de la categoria

                            self.percentageOfChanges = 0;
                            self.percentageOfChangesVsSuggested = 0;

                            this.newImpact = changePriceNewImpact;

                            //if (!rowNode.selected)
                            //    rowNode.setSelected(true);
                        }
                        else {
                            //Hago esto para evitar restarlo 2 veces
                            if (this.newPrice != null && this.newPrice != '') {
                                self.numberOfNewPrices--;

                                self.salesWithNewPrices -= (this.newPrice - this._Price) * this.units;

                                self.averagePriceChanges = Math.round((self.salesWithNewPrices / self.salesWithCurrentPrices - 1) * 10000) / 10000;
                                self.averagePriceNewVsSuggestedChanges = Math.round((self.salesWithNewPrices / self.salesWithSuggestedPrices - 1) * 10000) / 10000;
                            }

                            //Cambios en los KPIs del Header
                            self.newImpact -= this.newImpact;
                            self.newImpactShare = self.newImpact / self.categoryMargin; //Impacto sobre total margen de la categoria

                            self.percentageOfChanges = 0;
                            self.percentageOfChangesVsSuggested = 0;

                            this.newPrice = null;
                            this.priceDelta = null;
                            this.newImpact = null;
                            this.newGrossMargin = null;
                            this.newGrossMarginAbs = null;
                            this.competitorsPriceVsNew = null;
                            this.newValidFrom = null;
                            this.newValidTo = null;
                        }

                        if (this.suggestedPrice != null) {
                            if (newPrice == this.suggestedPrice) {
                                self.numberOfSuggestionsAccepted++;
                            }
                            else {
                                self.numberOfSuggestionsAccepted--;
                            }
                            self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                        }

                        this.priceChangeCount++;
                    }
                },
                suggestionDelta: function () {
                    if (!this.suggestedPrice)
                        return 0;

                    return (this.suggestedPrice / this._Price) - 1;
                },
                suggestionDeltaAmount: function () {
                    if (!this.suggestedPrice)
                        return 0;

                    return this.suggestedPrice - this._Price
                },
                isMarginOutOfGuidelines: function () {
                    var outOfGuidelines = false;
                    if (this.newPrice) {
                        //console.log('this', this);

                        if (this.minMargin != null && this.minMargin != 0 && this.newGrossMargin < this.minMargin) {
                            //console.log(true);
                            outOfGuidelines = true;
                        }
                        if (this.maxMargin != null && this.maxMargin != 0 && this.newGrossMargin > this.maxMargin) {
                            //console.log(true);
                            outOfGuidelines = true;
                        }
                        //if (this.minMarginAmount && Math.abs(this._Price - this.newPrice) < this.minMarginAmount) {
                        if (this.minMarginAmount && this.newGrossMarginAbs < this.minMarginAmount) {
                            //console.log('this._Price', this._Price);
                            //console.log('this.newPrice', this.newPrice);

                            //console.log('Math.abs(this._Price - this.newPrice)', Math.abs(this._Price - this.newPrice));
                            //console.log('this.minMarginAmount', this.minMarginAmount);
                            //console.log(true);
                            outOfGuidelines = true;
                        }
                        if (this.maxMarginAmount && this.newGrossMarginAbs > this.maxMarginAmount) {
                            //if (this.maxMarginAmount && Math.abs(this._Price - this.newPrice) > this.maxMarginAmount) {
                            //console.log(true);
                            outOfGuidelines = true;
                        }
                    }

                    return outOfGuidelines;
                },
                isVariationOutOfGuidelines: function () {
                    var outOfGuidelines = false;
                    if (this.newPrice) {

                        if (this.minVariation != null && this.minVariation != 0 && this.priceDelta < this.minVariation) {
                            outOfGuidelines = true;
                        }
                        if (this.maxVariation != null && this.maxVariation != 0 && this.priceDelta > this.maxVariation) {
                            outOfGuidelines = true;
                        }
                        if (this.minVariationAmount && Math.abs(this._Price - this.newPrice) < this.minVariationAmount) {
                            outOfGuidelines = true;
                        }
                        if (this.maxVariationAmount && Math.abs(this._Price - this.newPrice) > this.maxVariationAmount) {
                            outOfGuidelines = true;
                        }
                    }

                    return outOfGuidelines;
                },
                isSuggested: function () {
                    return this.status == 0 && this.suggestedPrice;
                },
                isPending: function () {
                    return this.newPrice && this.status == 1;
                },
                isEndorsed: function () {
                    return this.newPrice && this.status == 2;
                },
                hasStrongIncreases: function () {
                    if (this.newPrice != null) {
                        if (this.newPrice != null && this.maxVariation != null && this.maxVariation != 0 && this.priceDelta > this.maxVariation)
                            return true;
                        else if (this.newPrice != null && this.maxVariationAmount != null && this.maxVariationAmount != 0 && this.priceDeltaAmount > this.maxVariationAmount)
                            return true;
                    } else if (this.suggestedPrice != null) {
                        if (this.maxVariation != null && this.maxVariation != 0 && this.suggestedPriceDelta > this.maxVariation)
                            return true;
                        else if (this.maxVariationAmount != null && this.maxVariationAmount != 0 && this.suggestedPriceDeltaAmount > this.maxVariationAmount)
                            return true;
                    }
                },
                hasStrongDecreases: function () {

                    if (this.newPrice != null) {
                        if (this.newPrice != null && this.minVariation != null && this.minVariation != 0 && this.priceDelta < this.minVariation)
                            return true;
                        else if (this.newPrice != null && this.minVariationAmount != null && this.minVariationAmount != 0 && this.priceDeltaAmount < this.minVariationAmount)
                            return true;
                    } else if (this.suggestedPrice != null) {
                        if (this.maxVariation != null && this.maxVariation != 0 && this.suggestedPriceDelta < this.minVariationAmount)
                            return true;
                        else if (this.minVariationAmount != null && this.minVariationAmount != 0 && this.suggestedPriceDeltaAmount < this.minVariationAmount)
                            return true;
                    }

                },
                hasLowMargin: function () {
                    if (this.newPrice != null && this.minMargin != null && this.minMargin != 0 && this.newGrossMargin < this.minMargin)
                        return true;
                    else if (this.newPrice != null && this.minMarginAmount != null && this.minMarginAmount != 0 && this.newGrossMarginAbs < this.minMarginAmount)
                        return true;
                },
                hasHighMargin: function () {
                    if (this.newPrice != null && this.maxMargin != null && this.maxMargin != 0 && this.newGrossMargin > this.maxMargin)
                        return true;
                    else if (this.newPrice != null && this.maxMarginAmount != null && this.maxMarginAmount != 0 && this.newGrossMarginAbs > this.maxMarginAmount)
                        return true;
                },
                hasNotSignificantVariation: function () {
                    if ((this.newPrice != null && this.significantVariation != null && this.significantVariation != 0 && Math.abs(this.priceDelta) < this.significantVariation)
                        || (this.newPrice != null && this.significantVariation != null && this.significantVariation != 0 && Math.abs(this.priceDeltaAmount) < this.significantVariation)
                        || (this.newPrice != null && this.significantVariationInMargin != null && this.significantVariationInMargin != 0 && Math.abs(this.newImpact) < this.significantVariationInMargin))
                        return true;
                },
                hasNotFollowedSuggestion: function () {
                    if (this.newPrice != null && this.newPrice != 0 && this.suggestedPrice != null && this.suggestedPrice != 0 && this.suggestedPrice != this.newPrice)
                        return true;
                },
                hasOverlaps: function () {
                    if (this.overlappingPromotions && this.overlappingPromotions.length > 0)
                        return true;
                },
                isParent: function () {
                    if (!this.mainItemId && !this.mainPriceListId && this.children.length > 0)
                        return true

                    return false;
                },
                isChild: function () {
                    if (this.mainItemId || this.mainPriceListId)
                        return true;

                    return false;
                },
                getImpact: function () {
                    if (price.newImpact)
                        return Math.abs(price.newImpact);
                    if (price.suggestedImpact)
                        return Math.abs(price.suggestedImpact);

                    return 0;
                },
                getMarginInUSDxM3: function () {
                    return (this.grossMarginAbs / self.exchangeRate.fxRate) * 1000;
                },
                getSuggestedMarginInUSDxM3: function () {
                    var rv = (this.suggestedGrossMarginAbs / self.exchangeRate.fxRate) * 1000;
                    return rv > 0 ? rv : null;
                },
                getNewMarginInUSDxM3: function () {
                    var rv = (this.newGrossMarginAbs / self.exchangeRate.fxRate) * 1000;
                    return rv > 0 ? rv : null;
                }
            }

            for (var i = 0; i < prices.length; i++) {
                _.assign(prices[i], extendedPrice);
            }

        }

        function flattenChildren(prices) {

            prices = _.orderBy(prices, function (p) {
                if (p.newImpact)
                    return Math.abs(p.newImpact);
                if (p.suggestedImpact)
                    return Math.abs(p.suggestedImpact);

                return 0;
            }, ['desc']);

            var flattenPrices = [];
            var index = 0;

            for (var i = 0; i < prices.length; i++) {

                var price = prices[i];
                price.index = index++;
                price.showChilds = false;
                flattenPrices.push(price);

                for (var j = 0; j < prices[i].children.length; j++) {

                    var childPrice = prices[i].children[j];
                    childPrice.index = index++;
                    childPrice.showMe = false;
                    childPrice.parentPriceId = price.priceId;

                    flattenPrices.push(childPrice);
                }

            }

            return flattenPrices;
        }

        function closeAllPopovers() {
            self.allPrices.forEach(function (p) {
                if (!p.hoveringPopover)
                    p.popOverIsOpen = false;
            });
        }

        function getItemsToSave() {
            var itemsToSave = [];

            //Agregamos los items seleccionados que tengan un Nuevo Precio distinto al precio actual o un 
            angular.forEach(self.allPrices, function (price) {

                if (price.dirty) {
                    if ((price.newPrice && price.newPrice != 0 && price.newPrice != price._Price)
                        || (price.priceListIsBase && price.priceIsFirst && price.newPrice && price.newPrice != 0) //esta condicion es para permitir aceptar sugerencias para primer registro de precio que ingresa a prisma.
                        || (price.newValidFrom && price.newValidFrom != price.validFrom)
                        || (price.newValidFrom && price.newValidFrom != price.validFrom)) {
                        itemsToSave.push(price);
                    }
                }
            });

            return itemsToSave;
        }

        function saveNewPrices(alertIfNochangesToSave) {

            var itemsToSave = getItemsToSave();

            var newPrices = getNewPricesToSaveOrEndorse(itemsToSave, false);

            if (itemsToSave.length > 0) {
                return priceService.decisions.saveNewPrices($stateParams.categoryId, newPrices);
            }
            else if (alertIfNochangesToSave) {
                $timeout(function () {
                    swal(translations.Attention, translations.NoChangesWereRecorded, "warning");
                }, 500);
            }
        }

        function getNewPricesToSaveOrEndorse(itemsToSave, endorse) {

            var newPrices = [];

            if (itemsToSave.length > 0) {
                //Como usamos este mismo control para endosar precios tenemos que identificar que acci�n estamos llevando a cabo para pasarsela al objeto que se va a guardar
                //2 = Endoso, 1 = Guardado Regular
                var priceDecisionAction = endorse ? 2 : 1;

                newPrices = _.map(itemsToSave, function (x) {
                    return {
                        priceId: x.priceId,
                        itemId: x.itemId,
                        salesUnitOfMeasureId: x.salesUnitOfMeasureId,
                        priceListId: x.priceListId,
                        _Price: x._Price,
                        validFrom: x.validFrom,
                        validTo: x.validTo,
                        newPrice: x.newPrice,
                        newValidFrom: x.newValidFrom,
                        newValidTo: x.newValidTo,
                        status: x.status,
                        suggestedPrice: x.suggestedPrice,
                        priceSuggestionId: x.priceSuggestionId,
                        priceDecisionAction: priceDecisionAction,
                        minMargin: x.minMargin,
                        maxMargin: x.maxMargin,
                        minVariation: x.minVariation,
                        maxVariation: x.maxVariation,
                        significantVariation: x.significantVariation,
                        significantVariationInMargin: x.significantVariationInMargin,
                        parentRuleItemId: x.parentRuleItemId,
                        parentRuleZoneId: x.parentRuleZoneId,
                        mainItemId: x.mainItemId,
                        priceListIsBase: x.priceListIsBase,
                        priceIsFirst: x.priceIsFirst,
                        mainPriceListId: x.mainPriceListId,
                        children: _.map(x.children, function (child) {
                            return {
                                priceId: child.priceId,
                                itemId: child.itemId,
                                salesUnitOfMeasureId: child.salesUnitOfMeasureId,
                                priceListId: child.priceListId,
                                _Price: child._Price,
                                validFrom: child.validFrom,
                                validTo: child.validTo,
                                newPrice: child.newPrice,
                                newValidFrom: child.newValidFrom,
                                newValidTo: child.newValidTo,
                                status: child.status,
                                suggestedPrice: child.suggestedPrice,
                                priceSuggestionId: child.priceSuggestionId,
                                priceDecisionAction: child.priceDecisionAction,
                                minMargin: child.minMargin,
                                maxMargin: child.maxMargin,
                                minVariation: child.minVariation,
                                maxVariation: child.maxVariation,
                                significantVariation: child.significantVariation,
                                significantVariationInMargin: child.significantVariationInMargin,
                                parentRuleItemId: child.parentRuleItemId,
                                parentRuleZoneId: child.parentRuleZoneId,
                                mainItemId: child.mainItemId,
                                priceListIsBase: child.priceListIsBase,
                                priceIsFirst: child.priceIsFirst,
                                mainPriceListId: child.mainPriceListId
                            }
                        })
                    }
                });
            }

            return newPrices;
        }

        function onFilterChanged() {
            $timeout(function () {
                //$('.tab-row').hide()
                $('.tab-row')
                    .children('td')
                    .animate({ paddingTop: 0, paddingBottom: 0 }, 0)
                    .children()
                    .slideUp(0, function () { $('.tab-row').hide() });
            }, 0);
        }



        /***Fin Nuevas Funciones****/



        function startProgress() {
            self.busy = 0;
            return $interval(function () { self.busy += 0.05; }, 250, 16);
        }

        function stopProgress(interval) {
            $interval.cancel(interval);
            self.busy = 0.95;
            $timeout(function () { self.busy = false; }, 500);
        }

        function cellClicked(cell) {
            if (cell.colDef.field == "competitorsPrice") {
                if (cell.data != null)
                    $state.go('prices.priceMap', { itemId: cell.data.itemId, priceListId: cell.data.priceListId });
            }
            else if (cell.colDef.field == "overlappingPromotions") {
                if (cell.data != null)
                    self.showOverlaps(cell.data.overlappingPromotions);
            }
        }

        function rowSelected(event) {
            if (event.node.selected) {
                var data = event.node.data;

                if (data.suggestedPrice && (data.newPrice == null || data.newPrice == '' || data.newPrice == 0)) {

                    changePrice(data.suggestedPrice, event.node);
                } else if (data.suggestedPrice) {
                    self.numberOfSuggestionsAccepted++;
                    self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                }
            }
            else {
                if (!event.node.data.hasNewPriceFromDb)
                    changePrice(null, event.node);
                else if (event.node.data.suggestedPrice) {
                    self.numberOfSuggestionsAccepted--;
                    self.percentageOfAcceptedSuggestions = self.numberOfSuggestionsAccepted > 0 ? Math.round((self.numberOfSuggestionsAccepted / self.numberOfSuggestions) * 10000) / 10000 : 0;
                }
            }
        }

        function marginGuidelinesAlert(message) {
            changePriceAfterGuidelineCheck(changePriceNewPrice, changePriceRowNode);

            //swal({
            //    title: "Esta seguro?",
            //    text: message,
            //    type: "warning",
            //    showCancelButton: true,
            //    confirmButtonColor: "#1AB394",
            //    confirmButtonText: "Continuar",
            //    cancelButtonText: "Cancelar",
            //    closeOnConfirm: false,
            //    closeOnCancel: false
            //},
            //  function (isConfirm) {
            //      if (isConfirm) {
            //          swal("Confirmado", "Se cambio el precio (requerira endoso)", "success");
            //          changePriceAfterGuidelineCheck(changePriceNewPrice, changePriceRowNode);
            //      } else {
            //          swal("Cancelado", "Se conservo el precio anterior", "error");
            //          self.gridOptions.api.refreshRows([changePriceRowNode]);
            //      }
            //  });
        }

        function markAsReviewed(priceType, ignorePendingSuggestions) {

            var subCategory = $stateParams.subcategory != '' ? $stateParams.subcategory : ' ';

            priceService.decisions.markAsReviewed($stateParams.categoryId, $stateParams.categoryGroup, $stateParams.category, subCategory, priceType, ignorePendingSuggestions)
                .then(function () {
                    swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                    loadData();

                }).catch(function (fallback) {
                    console.log(fallback);
                    swal(translations.ErrorTitleSwal, translations.ErrorWhenWantingToMarkThisCategoryAsRevised, "error");
                });
        }

        function resetKPIs() {
            changePriceNewImpact = 0;
            self.numberOfNewPrices = 0;
            self.averagePriceChanges = 0;
            self.averagePriceNewVsSuggestedChanges = 0;
            self.newImpact = 0;
            self.newImpactShare = 0;
            self.percentageOfChanges = 0;
            self.percentageOfChangesVsSuggested = 0;
            self.numberOfSuggestionsAccepted = 0;
            self.suggestedImpact = 0;
            self.categoryMargin = 0;
            self.salesWithCurrentPrices = 0;
            self.salesWithSuggestedPrices = 0;
            self.numberOfSuggestions = 0;

            self.salesWithNewPrices = 0;

            self.suggestedImpactShare = 0;

        }


        //$(window).scroll(function () {
        //    if ($(window).scrollTop() + $(window).height() > ($(document).height() * 0.95)) {
        //        if (pageLoaded) {
        //            $timeout(function () {
        //                self.pageNumber++;
        //            }, 0)
        //        }
        //    }
        //});

        self.totalFilteredPrices = self.allPrices.length;

        self.next = function () {
            if (pageLoaded) {
                $timeout(function () {
                    if (self.pageNumber + 1 < self.numberOfPages())
                        self.pageNumber++;
                }, 0)
            }
        }

        self.previous = function () {
            if (pageLoaded) {
                $timeout(function () {
                    if (self.pageNumber > 0)
                    self.pageNumber--;
                }, 0)
            }
        }


        self.numberOfPages = function () {
            return Math.ceil(self.totalFilteredPrices / self.pageSize);
        }


        self.isLoadingPrices = false;

        function loadData() {

            self.isLoadingPrices = true;

            resetKPIs();

            priceService.exchangeRates.getCurrent().then(rate => self.exchangeRate = rate);


            priceService.decisions.getPrices($stateParams.categoryId, self.isEndorse, 0)
                .then(function (prices) {
                    self.isLoadingPrices = false;
                                       
                    self.allPrices = flattenChildren(prices);
                    self.totalFilteredPrices = self.allPrices.length;

                    //enriquese los dtos con funcionalidad
                    extendPriceObjects(self.allPrices);

                    //actualizo los contadores de los filters
                    updateFilterCounters();

                    //traigo las zonas de precios
                    priceService.pricelists.getPriceLists()
                        .then(function (pricelists) {
                            self.pricelists = pricelists;

                            var markets = pricelists.map(x => { return { marketId: x.marketId, market: x.market } })
                            self.markets = ian.removeDuplicates(markets, 'marketId');

                            var brands = pricelists.map(x => { return { brandId: x.brandId, brand: x.brand } })
                            self.brands = ian.removeDuplicates(brands, 'brandId');

                            var regions = pricelists.map(x => { return { regionId: x.regionId, region: x.region } })
                            self.regions = ian.removeDuplicates(regions, 'regionId');

                            //agrego datos de segmentacion de zona a cada price
                            pricelists.forEach(priceList => {
                                var prices = self.allPrices.filter(price => price.priceListId == priceList.id);
                                prices.forEach(p => {
                                    p.brandId = priceList.brandId;
                                    p.regionId = priceList.regionId;
                                    p.marketId = priceList.marketId;
                                });
                            });
                        });


                    //TODO: eliminar esta linea y ver de donde salen los issues realmente.
                    //prices[2].issues = 3;
                    //prices[3].status = 2;
                    //prices[3].newPrice = 2500;
                    //prices[3].suggestedPrice = null;

                    //self.gridOptions.api.setRowData(prices);

                    if (prices.length > 0) {

                        self.category = prices[0].category;

                        //suggestedImpact
                        angular.forEach(self.allPrices, function (price) {

                            //agrego algunos extra al dto
                            price.hasNewPriceFromDb = price.newPrice ? true : false;
                            price.outOfGuidelines = false;

                            if (price.tagIds) {
                                let priceTagIds = price.tagIds.split(',');
                                priceTagIds = priceTagIds.map(function (x) { return parseInt(x.trim()); });
                                price.itemTags = self.tags.filter(function (x) { return priceTagIds.includes(x.id) });
                            }

                            //Sumarizo
                            self.suggestedImpact += price.suggestedImpact;
                            self.categoryMargin += price.grossMarginAbs * price.units;
                            self.salesWithCurrentPrices += price._Price * price.units;

                            if (price.newPrice > 0) {
                                self.salesWithNewPrices += price.newPrice * price.units;
                                self.numberOfNewPrices++;
                            }
                            else
                                self.salesWithNewPrices += price._Price * price.units;

                            if (price.suggestedPrice > 0)
                                self.salesWithSuggestedPrices += price.suggestedPrice * price.units;
                            else
                                self.salesWithSuggestedPrices += price._Price * price.units;

                            if (price.suggestedPrice > 0)
                                self.numberOfSuggestions++;

                            self.newImpact += price.newImpact;

                        });

                        self.newImpactShare = self.newImpact / self.categoryMargin; //Impacto sobre total margen de la categoria
                        self.suggestedImpactShare = self.suggestedImpact / self.categoryMargin;

                        self.averagePriceChanges = Math.round((self.salesWithNewPrices / self.salesWithCurrentPrices - 1) * 10000) / 10000;
                        self.averagePriceNewVsSuggestedChanges = Math.round((self.salesWithNewPrices / self.salesWithSuggestedPrices - 1) * 100) / 100;
                    }

                    //loadChartData();


                    $timeout(function () {
                        //$('.tab-row').hide()
                        $('.tab-row')
                            .children('td')
                            .animate({ paddingTop: 0, paddingBottom: 0 }, 0)
                            .children()
                            .slideUp(0, function () { $('.tab-row').hide() });
                    }, 0);

                    pageLoaded = true;
                });

            //FILTROS DE ZONA GEOGRAFICA PARA LAREBAJA
            if (self.companyCode == 'LaRebaja') {
                adminService.stores.getStoresZoning()
                    .then(function (zoning) {
                        self.filters.zoning = zoning;
                        self.departments = zoning.map(x => { return { departmentId: x.id, department: x.name } })

                        angular.forEach(zoning, function (dep) {
                            angular.forEach(dep.children, function (city) {
                                angular.forEach(city.children, function (sec) {
                                    self.sectors.push({ sectorId: sec.id, sector: sec.name });
                                    angular.forEach(sec.children, function (store) {
                                        self.stores.push({ storeId: store.id, store: store.name });
                                    });
                                });
                            });
                        });

                    });
            }
        }
        

        function loadTags() {
            adminService.tags.getTags()
                .then(function (tags) {
                    self.tags = tags;
                });
            adminService.tags.getTagGroups()
                .then(function (tagGroups) {
                    self.groupTag = tagGroups;
                })
        }

        function getDataFromCategorySummary() {
            //Seteo el titulo tipo Breadcrumb
            var categoryGroup = decodeURIComponent($stateParams.categoryGroup.replace(/-/g, " ").replace(/_/g, "\/"));
            var category = decodeURIComponent($stateParams.category.replace(/-/g, " ").replace(/_/g, "\/"));
            var subcategory = decodeURIComponent($stateParams.subcategory.replace(/-/g, " ").replace(/_/g, "\/"));

            self.title = categoryGroup
            if (category != '') {
                self.title += ' / ' + category;
                if (subcategory != '') {
                    self.title += ' / ' + subcategory;
                }
            }
        }


        //Filtros

        self.showFilters = false;

        self.toggleFilters = function () {

            self.showFilters = !self.showFilters;

            if (self.showFilters) {
                $timeout(function () {

                    $window.onclick = function (event) {
                        closeFilterWhenClickingOutside(event);
                    }
                }, 100);
            } else {
                $window.onclick = null;
            }

        }

        function closeFilterWhenClickingOutside(event) {

            var clickedElement = event.target;
            if (!clickedElement) return;

            var elementClasses = clickedElement.classList;
            var clickedOnFilterDiv = elementClasses.contains('isFilter')
                || elementClasses.contains('chosen-choice')
                || elementClasses.contains('chosen-choices')
                || elementClasses.contains('search-field')
                || elementClasses.contains('default')
                || elementClasses.contains('chosen-container')
                || elementClasses.contains('chosen-container')
            if (!clickedOnFilterDiv) {
                self.closeFilters();
            }

        }

        self.closeFilters = function () {
            $timeout(function () {
                self.showFilters = false;
                $window.onclick = null;
            });

        }

        self.getFilterClass = function () {
            if (self.showFilters == true)
                return 'move-right';
            else
                return '';
        }

        //Filtros

        //Barra de seleccion
        self.showMultipleSelectionBar = false;
        self.totalCheckedPrices = 0;

        self.onCheckboxChanged = function () {
            self.totalCheckedPrices = getCheckedPrices().length;
            self.showMultipleSelectionBar = self.totalCheckedPrices > 0;
        }

        self.showBar = function () {
            self.showMultipleSelectionBar = true;
        }

        var isClosingBar = false;
        self.closeBar = function () {
            if (self.showMultipleSelectionBar == true) {
                isClosingBar = true;

                getCheckedPrices().forEach(p => p.isChecked = false);

                $timeout(function () {
                    isClosingBar = false;
                    self.showMultipleSelectionBar = false;
                    //$window.onclick = null;
                });
            }
        }

        self.animationCloseBar = function () {
            if (isClosingBar == true)
                return 'closeFilters';
            else
                return 'showBarAnimation';
        }
        //Fin Barra de seleccion

        self.isSimulationActive = false;

        self.stopSimulation = function () {
            self.allPrices.filter(p => p.isSimulated).forEach(p => {
                p.isSimulated = false;
                p.changePrice(null);
            });

            self.isSimulationActive = self.allPrices.filter(p => p.isSimulated).length > 0;
        }

        self.confirmSimulation = function () {
            self.allPrices.forEach(p => p.isSimulated = false);
            self.isSimulationActive = self.allPrices.filter(p => p.isSimulated).length > 0;
        }

        //Filtro collapse
        self.isNavCollapsed = true;
        self.isCollapsedHorizontal = false;
        self.isCollapsed = true;
        self.isStateCollapsed = false;
        self.isZoneCollapsed = true;
        self.isRegionsCollapsed = true;
        self.isMarketsCollapsed = true;
        self.isBrandsCollapsed = true;
        self.isDepartmentsCollapsed = true;
        self.isSectorsCollapsed = true;
        self.isStoresCollapsed = true;
        self.isPriceTypeCollapsed = true;
        self.isActionCollapsed = true;
        self.isSituationCollapsed = true;

        //Filtro collapse
        var translations = null;
        self.filters = {};

        $translate(["All", "Suggested", "Endorsed", "Pending", 'Regular', 'Competitive', 'Decrease', 'Increase', 'NewPrices', 'Current', 'Basket', 'Promotion', 'Own', "StrongFalls", "StrongRises", "DepressedMargins", "HighMargins", "LittleSignificantChanges", "SuggestedNotRespected", "Overlaps"])
            .then(function (all) {
                translations = all;

                self.filters = {
                    status: [
                        { id: 'All', name: translations.All, checked: true, count: 0 },
                        { id: 'Suggested', name: translations.Suggested, checked: false, count: 0 },
                        { id: 'Pending', name: translations.Pending, checked: false, count: 0 },
                        { id: 'Endorsed', name: translations.Endorsed, checked: false, count: 0 }
                    ],
                    priceLists: [],
                    brands: [],
                    regions: [],
                    markets: [],
                    departments: [],
                    sectors: [],
                    stores: [],
                    zoning: [],
                    priceTypes: [
                        { id: 'All', name: translations.All, value: 0, checked: true, count: 0 },
                        { id: 'Regular', name: translations.Regular, value: 6, checked: false, count: 0 },
                        { id: 'Competitive', name: translations.Competitive, value: 5, checked: false, count: 0 },
                    ],
                    actions: [
                        { id: 'All', name: translations.All, value: 0, checked: true, count: 0 },
                        { id: 'Decrease', name: translations.Decrease, value: 1, checked: false, count: 0 },
                        { id: 'Increase', name: translations.Increase, value: 2, checked: false, count: 0 },
                    ],
                    situations: [
                        { name: translations.All, value: 0, checked: true, count: 0 },
                        { name: translations.NewPrices, value: 1, checked: false, count: 0 },
                        { name: translations.StrongFalls, value: 2, checked: false, count: 0 },
                        { name: translations.StrongRises, value: 3, checked: false, count: 0 },
                        { name: translations.DepressedMargins, value: 4, checked: false, count: 0 },
                        { name: translations.HighMargins, value: 5, checked: false, count: 0 },
                        { name: translations.LittleSignificantChanges, value: 6, checked: false, count: 0 },
                        { name: translations.SuggestedNotRespected, value: 7, checked: false, count: 0 },
                        { name: translations.Overlaps, value: 8, checked: false, count: 0 },
                    ],
                    countStatus: function () {
                        var c = 0;
                        angular.forEach(this.status, function (sf) { if (sf.checked === true) c++; });
                        return c;
                    },
                    countPriceLists: function () {
                        return this.priceLists.length;
                    },
                    countPriceTypes: function () {
                        var c = 0;
                        angular.forEach(this.priceTypes, function (pt) { if (pt.checked === true) c++; });
                        return c;
                    },
                    countActions: function () {
                        var c = 0;
                        angular.forEach(this.actions, function (a) { if (a.checked === true) c++; });
                        return c;
                    },
                    countSituations: function () {
                        var c = 0;
                        angular.forEach(this.situations, function (s) { if (s.checked === true) c++; });
                        return c;
                    }
                }
            });

        var translations = null;

        function init() {

            priceService.getPriceTypes().then(
                function (priceTypes) {
                    self.priceTypes = priceTypes;
                });

            $translate(["AlertWillLoseChanges", "PricesOfThisCategoryAsRevised", "CompetitionPricesOfThisCategoryAsRevised", "NormalPricesOfThisCategoryAsRevised", "DiscardConfirmTitle", "ContinueSwal", "CancelSwal",
                "PricesOfThisCategoryAsRevisedPartial", "CompetitionPricesOfThisCategoryAsRevisedPartial", "NormalPricesOfThisCategoryAsRevisedPartial",
                , "ErrorTitleSwal", "ErrorSwalSubtitle", "IgnoreSelectedSuggestions", "ConfirmedSwal", "ConfirmedIgnoreSelectedSuggestions", "Attention", "NoChangesWereRecorded", "EndorseTheModifiedPrices"
                , "ConfirmedEndorseTheSelectedPrices", "ErrorWhenWantingEndorsePrices", "RejectionComment", "RejectionCommentSubtitle", "RejectedItems", "RejectedItemsSubtitle", "TheFollowingChangesWillBeSaved"
                , "PriceChangesOp", "IgnoredSuggestionsOp", "EndorsedPricesOp", "RejectedPricesOp", "ConfirmedSwalSubtitle", "SuggestionTooltip", "CurrentTooltip", "NewTooltip", "EndorsedTooltip", "ErrorWhenWantingToMarkThisCategoryAsRevised", "UpdateDates", "UpdateDatesSubtitleOp1", "UpdateDatesSubtitleOp2"
                , "NewPriceHasBeenRejected", "GoToTheAuditTab", "PriceUpdateConfirmTitle", "PriceUpdateConfirmText", "ValidationSwalSubtitle", "Success", "DoYouWishToIgnorePendingSuggestions", "Yes", "HasBeenSuccessfullySavedSwal", "NewTagGroup"
                , "Pr_Decisions_SimulationWarningOnSave_Title", "Pr_Decisions_SimulationWarningOnSave_Desc"
            ])
                .then(function (all) {
                    translations = all;


                    //Load data for title and endorsement
                    getDataFromCategorySummary();

                    //load data
                    loadData();
                    loadTags();

                });
        }


        init();

    });

