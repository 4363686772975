angular.module("prisma")
    .controller("addItemToLayoutCtrl", function ($scope, $q, $filter, $window, $timeout, $rootScope, $state, $stateParams, promotionsService, homeService, authService, serverService, Upload, ngDialog) {

        const ART_PUBLICADO = 1;
        const ART_SUPLENTE = 2;
        const ART_SINUBICACION = 3;
        const ART_ALTERNATIVO = 6;

        var self = this;

        //Permissions
        self.canpublishValidacionFinal = authService.hasPermission('promotion_publish_validacionFinal');
        self.canpublishSubidaDePieza = authService.hasPermission('promotion_publish_SubidaDePiezaParaRevision');
        self.canpublishInputArmadoDePieza = authService.hasPermission('promotion_publish_InputArmadoPieza');
        self.canPublishSecondRevision = authService.hasPermission('promotion_publish_SegundaRevision');
        self.canPublishCargaArticulos = authService.hasPermission('promotion_publish_CargaInicialYPrimeraRevision');
        self.canDownloadPdfInFinalValidation = authService.hasPermission('promotion_edit_final_validation');
        self.isSuperUser = authService.hasPermission('promotion_superUser');
        self.disableMassiveCharges = false;
        self.userCategories = authService.authentication.categories;

        self.isLayoutCompare = false;
        self.isLayoutEditable = false;
        self.isItemEditEnabled = true;
        self.showRelatedItems = false;

        self.isActivationTask = false;

        //variants lookup
        self.itemLookupText = '';
        self.itemsInLookup = [];
        self.selectedItemInModal = null;
        var previousLookupText = '';

        self.variantsHaveLength = false;

        self.selectedPage = null;

        self.selectedPages = [];

        self.showAllitems = false;

        self.showIndividualItems = false;
        
        self.isLoading = true;

        self.promotionMediaPageTypes = promotionsService.promotionMediaPageTypes;

        self.promotion = {
            id: 0,
            name: '',
            pages: []
        };

        self.chats = [];

        self.showRelated = function (show) {
            self.showRelatedItems = show;
        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        self.batchLoad = function () {

            //newScope.layout = self.layout;
            //newScope.selectedPage = self.selectedPage;
            //newScope.promotionMediaPageTypes = self.promotionMediaPageTypes;

            self.currentStep = 1;
            self.gridOptions = {
                columnDefs: [
                    {
                        headerName: 'Severidad',
                        width: 100,
                        pinned: 'left',
                        field: 'severity',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                        rowGroupIndex: 0,
                        cellRenderer: function (params) {
                            var severityName = '';
                            switch (params.value) {
                                case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                                case 'Warning': { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                                case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break; }
                            }
                            return severityName;
                        }
                    },
                    {
                        headerName: 'Fila',
                        width: 50,
                        pinned: 'left',
                        field: 'row',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Categoria',
                        width: 150,
                        rowGroupIndex: 1,
                        pinned: 'left',
                        field: 'category',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Descripcion',
                        width: 350,
                        pinned: 'left',
                        field: 'description',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                ],
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                rowHeight: 35,
                suppressHorizontalScroll: false,
                suppressCellSelection: true,
                groupUseEntireRow: true,
                groupSuppressAutoColumn: false,
                groupDefaultExpanded: 0,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
            }

            self.showNextStep = function () {
                if (self.currentStep > 0 && self.currentStep < 4) {
                    self.currentStep++;
                    loadResults([]);
                }
            }

            function tildes_unicode(str) {

                str = str.replace(/á/g, "&aacute;");
                str = str.replace(/é/g, "&eacute;");
                str = str.replace(/í/g, "&iacute;");
                str = str.replace(/ó/g, "&oacute;");
                str = str.replace(/ú/g, "&uacute;");

                str = str.replace(/Á/g, "&Aacute;");
                str = str.replace(/É/g, "&Eacute;");
                str = str.replace(/Í/g, "&Iacute;");
                str = str.replace(/Ó/g, "&Oacute;");
                str = str.replace(/Ú/g, "&Uacute;");

                str = str.replace(/ñ/g, "&ntilde;");
                str = str.replace(/Ñ/g, "&Ntilde;");
                return str;
            }

            self.downloadExcel = function () {
                var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

                //header
                html += '<th>Promocion ID</th>';
                html += '<th>Articulo</th>';
                html += '<th>UMV</th>';
                html += '<th>Descripcion SAP</th>';
                html += '<th>Pagina</th>';
                html += '<th>Tipo de Pagina</th>';
                html += '<th>Ubicacion</th>';
                html += '<th>Tipo de Ubicacion</th>';
                html += '<th>Layout</th>';
                html += '<th>Precio</th>';
                html += '<th>Precio Bonus</th>';
                html += '<th>Puntos Bonus</th>';
                html += '<th>Precio Normal</th>';
                html += '<th>Pronostico Un.</th>';
                html += '<th>Articulos Relacionados</th>';
                html += '<th>Articulos en Foto</th>';
                html += '<th>Tipo de Anuncio</th>';
                html += '<th>Tipo de Cobro</th>';
                html += '</tr></thead><tbody>';

                angular.forEach($filter('orderBy')(self.layout.details, ['rowNumber', 'colNumber'], false), function (detail) {
                    if (detail.position != null) {
                        var existingItem = null;
                        angular.forEach(self.selectedPage.items, function (item) {
                            if (item.position == detail.position && item.promotionItemType < 5) {
                                existingItem = item;
                            }
                        });

                        html += buildRow(existingItem, detail);
                    }
                });

                //agrego suplentes
                angular.forEach($filter('filter')(self.selectedPage.items, { promotionItemType: 2 }), function (item) {
                    html += buildRow(item, null);
                });

                //agrego sin ubicación.
                angular.forEach($filter('filter')(self.selectedPage.items, { promotionItemType: 3 }), function (item) {
                    html += buildRow(item, null);
                });

                html += '</tbody></table>';

                var exportHref = ExportToExcel(html, 'Pagina1');
                $timeout(function () {
                    location.href = exportHref;
                    self.showNextStep();
                }, 100); // trigger download
            }

            function buildRow(existingItem, detail) {

                var html = '';

                if (existingItem != null && existingItem.promotionMechanicId == null && existingItem.promotionItemType < 4) { //Solo los que son items, elimino las mecanicas, relacionados y reservados del download
                    var announceType = promotionsService.promotionMechanicAnnouncementTypes.find(function (x) { return x.id == existingItem.announceType; });
                    var chargeType = promotionsService.promotionMechanicPaymentTypes.find(function (x) { return x.id == existingItem.chargeType; });
                    var announceTypeLabel = announceType ? announceType.label : null;
                    var chargeTypeLabel = chargeType ? chargeType.label : null;
                    html += '<tr><td>' + $stateParams.promotionId +
                        '</td><td class="text">' + existingItem.code.replace(/^0+/, '') +
                        '</td><td>' + (existingItem.uom || '') +
                        '</td><td>' + existingItem.description +
                        '</td><td>' + self.selectedPage.pageNumber +
                        '</td><td>' + self.promotionMediaPageTypes[self.selectedPage.promotionMediaPageType - 1].label +
                        '</td><td class="text">' + (detail ? detail.position : existingItem.promotionItemType == 2 ? 0 : -1) +
                        '</td><td>' + (existingItem.promotionItemType == 2 ? 'Suplente' : existingItem.promotionItemType == 3 ? 'Sin ubicacion' : detail.isHighlighted ? 'Destacado' : 'Normal') +
                        '</td><td>' + self.layout.name +
                        '</td><td>' + (existingItem.promotionalPrice || '') +
                        '</td><td>' + (existingItem.loyaltyPrice || '') +
                        '</td><td>' + (existingItem.loyaltyPoints || '') +
                        '</td><td>' + (existingItem.normalPromotionPrice || '') +
                        '</td><td>' + (existingItem.forecast || '') +
                        '</td><td>' +//relacionados
                        '</td><td>' +//en foto
                        '</td><td>' + (announceTypeLabel || '') +
                        '</td><td>' + (chargeTypeLabel || '') +
                        '</td></tr>';
                }
                else {
                    var existingItemDescription = '';

                    if (existingItem != null) {
                        existingItemDescription = tildes_unicode(existingItem.description);
                    }
                    html += '<tr><td>' + $stateParams.promotionId +
                        '</td><td class="text">' +
                        '</td><td>' +
                        '</td><td>' + '<strong>' + existingItemDescription + '</strong>' +
                        '</td><td>' + self.selectedPage.pageNumber +
                        '</td><td>' + self.promotionMediaPageTypes[self.selectedPage.promotionMediaPageType - 1].label +
                        '</td><td>' + (detail ? detail.position : 0) +
                        '</td><td>' + (detail ? (detail.isHighlighted ? 'Destacado' : 'Normal') : '') +
                        '</td><td>' + self.layout.name +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td><td>' +
                        '</td></tr>';
                }

                return html;
            }

            self.uploadFile = function (files) {
                if (files && files.length > 0) {
                    self.isBusy = true;
                    var url = serverService.getApiUrl() + '/promotion/items/uploadItemsBatchFile/' + self.selectedPage.id;
                    Upload.upload({
                        url: url,
                        data: { file: files[0] }
                    }).then(function (response) {
                        self.selectedPage.idloadItemToPosition
                        var importerResults = response.data;
                        processImporterResults(importerResults);
                        if (!anyError(importerResults)) {
                            self.finishedUpload = true;
                        }
                    });
                }
            }

            self.validateUploadedFile = function () {
                self.isBusy3 = true;
                promotionsService.items.validateItemsBatchFile(self.selectedPage.id)
                    .then(function (dto) {
                        if (dto.some(function (x) { return x.category == 'VDQ_PromotionItems_ReplaceItem'; })) {
                            swal('ATENCIÓN', 'Se reemplazarán artículos ya cargados.', 'warning');
                        }
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedValidation = true;
                        }
                    });

            }

            self.reUploadFile = function () {
                self.currentStep = 2;
                loadResults([]);
                self.files = null;
                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;
            }

            self.updateItems = function () {
                self.isBusy4 = true;
                promotionsService.items.updateItemsInBatch(self.selectedPage.id)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedUpdate = true;
                        }
                    });

            }

            self.finish = function () {
                self.currentStep = 1;
                loadResults([]);

                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;

                ngDialog.close();
                loadPromotion();
            }

            function processImporterResults(importerResults) {
                self.isBusy = false;
                self.isBusy2 = false;
                self.isBusy3 = false;
                self.isBusy4 = false;

                if (importerResults.length > 0) {
                    loadResults(importerResults);
                }
                if (anyError(importerResults)) {
                    self.error = true;
                }
                else
                    self.error = false;
            }

            function loadResults(results) {
                self.gridOptions.api.setRowData(results);
                self.gridOptions.api.refreshView();

                self.gridVisible = results.length > 0 ? true : false;
            }

            function anyError(importerResults) {
                var error = false;
                for (var i = 0; i < importerResults.length; i++) {
                    if (importerResults[i].severity === 'Error')
                        error = true;
                }

                return error;
            }

            ngDialog.open({
                template: 'batchLoadDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                controller: function () {
                    return self;
                },
                controllerAs: 'ct',
            });
        }

        self.batchLoadExhibitions = function () {

            self.currentStep = 1;
            self.gridOptions = {
                columnDefs: [
                    {
                        headerName: 'Severidad',
                        width: 100,
                        pinned: 'left',
                        field: 'severity',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                        rowGroupIndex: 0,
                        cellRenderer: function (params) {
                            var severityName = '';
                            switch (params.value) {
                                case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                                case 'Warning': { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                                case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break; }
                            }
                            return severityName;
                        }
                    },
                    {
                        headerName: 'Fila',
                        width: 50,
                        pinned: 'left',
                        field: 'row',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Categoria',
                        width: 150,
                        rowGroupIndex: 1,
                        pinned: 'left',
                        field: 'category',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Descripcion',
                        width: 350,
                        pinned: 'left',
                        field: 'description',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                ],
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                rowHeight: 35,
                suppressHorizontalScroll: false,
                suppressCellSelection: true,
                groupUseEntireRow: true,
                groupSuppressAutoColumn: false,
                groupDefaultExpanded: 0,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
            }

            self.showNextStep = function () {
                if (self.currentStep > 0 && self.currentStep < 4) {
                    self.currentStep++;
                    loadResults([]);
                }
            }

            self.downloadExcelExhibitions = function () {

                promotionsService.items.getPromotionItemStores($stateParams.promotionId, self.selectedPage.pageNumber)
                    .then(function (itemStores) {

                        var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';

                        //header
                        html += '<th>Promocion ID</th>';
                        html += '<th>Articulo</th>';
                        html += '<th>UMV</th>';
                        html += '<th>Descripcion SAP</th>';
                        html += '<th>Pagina</th>';
                        html += '<th>Ubicacion</th>';
                        html += '<th>Tienda</th>';
                        html += '<th>Cod Exhibicion</th>';
                        html += '<th>Catalogar</th>';
                        html += '</tr></thead><tbody>';

                        angular.forEach($filter('orderBy')(self.layout.details, ['rowNumber', 'colNumber'], false), function (detail) {
                            if (detail.position != null) {
                                var existingItem = null;
                                angular.forEach(self.selectedPage.items, function (item) {
                                    if (item.position == detail.position && item.promotionItemType < 5) {
                                        existingItem = item;
                                    }
                                });

                                html += buildRowExhibition(existingItem, detail, itemStores);
                            }
                        });

                        //agrego suplentes
                        angular.forEach($filter('filter')(self.selectedPage.items, { promotionItemType: 2 }), function (item) {
                            html += buildRowExhibition(item, null, itemStores);
                        });

                        //agrego sin ubicación.
                        angular.forEach($filter('filter')(self.selectedPage.items, { promotionItemType: 3 }), function (item) {
                            html += buildRowExhibition(item, null, itemStores);
                        });

                        html += '</tbody></table>';

                        var exportHref = ExportToExcel(html, 'Pagina1');
                        $timeout(function () {
                            location.href = exportHref;
                            self.showNextStep();
                        }, 100); // trigger download

                    });
            }

            function buildRowExhibition(existingItem, detail, itemStores) {

                var html = '';
                var storesForItem = [];

                if (existingItem != null && existingItem.promotionMechanicId == null && existingItem.promotionItemType < 4) { //Solo los que son items, elimino las mecanicas y reservados del download

                    for (var i = 0; i < itemStores.length; i++) {
                        if (itemStores[i].promotionItemId == existingItem.id) {
                            storesForItem.push(itemStores[i]);
                        }
                    }

                    for (var i = 0; i < storesForItem.length; i++) {

                        html += '<tr><td>' + $stateParams.promotionId +
                            '</td><td class="text">' + existingItem.code.replace(/^0+/, '') +
                            '</td><td>' + (existingItem.uom || '') +
                            '</td><td>' + existingItem.description +
                            '</td><td>' + self.selectedPage.pageNumber +
                            '</td><td class="text">' + (detail ? detail.position : existingItem.promotionItemType == 2 ? 0 : -1) +
                            '</td><td>' + (storesForItem[i].storeCode || '') +
                            '</td><td>' + getExhibitionName(storesForItem[i].exhibitionTypeName) +
                            '</td><td>' + (storesForItem[i].toList ? 'SI' : 'NO' || '') +
                            '</td></tr>';
                    }

                }

                return html;
            }

            self.uploadFileExhibitions = function (files) {
                if (files && files.length > 0) {
                    self.isBusy = true;
                    var url = serverService.getApiUrl() + '/promotion/items/uploadItemExhibitionsBatchFile/' + self.selectedPage.id;
                    Upload.upload({
                        url: url,
                        data: { file: files[0] }
                    }).then(function (response) {
                        self.selectedPage.id
                        var importerResults = response.data;
                        processImporterResults(importerResults);
                        if (!anyError(importerResults)) {
                            self.finishedUpload = true;
                        }
                    });
                }
            }

            self.validateUploadedFileExhibitions = function () {
                self.isBusy3 = true;
                promotionsService.items.validateItemExhibitionsBatchFile(self.selectedPage.id)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedValidation = true;
                        }
                    });

            }

            self.reUploadFileExhibitions = function () {
                self.currentStep = 2;
                loadResults([]);
                self.files = null;
                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;
            }

            self.updateExhibitions = function () {
                self.isBusy4 = true;
                promotionsService.items.updateItemExhibitionsInBatch(self.selectedPage.id)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedUpdate = true;
                        }
                    });

            }

            self.finishExhibitions = function () {
                self.currentStep = 1;
                loadResults([]);

                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;

                ngDialog.close();
                loadPromotion();
            }

            function getExhibitionName(exhibition) {
                if (exhibition == "CABECERASALTAS")
                    return "CABECERAS ALTAS";
                if (exhibition == "CABECERASTOCADOR")
                    return "CABECERAS TOCADOR";
                if (exhibition == "CABECERASPASILLO")
                    return "CABECERAS PASILLO";
                if (exhibition == "CABECERASLICORES")
                    return "CABECERAS LICORES";
                if (exhibition == "CABECERASDEFRIO")
                    return "CABECERAS DE FRIO";
                if (exhibition == "LATERALES")
                    return "LATERALES";
                if (exhibition == "EXHIBIDORESDEPIE")
                    return "EXHIBIDORES DE PIE";
                if (exhibition == "VISICOOLERGRANDES")
                    return "VISICOOLER GRANDES";
                if (exhibition == "VISICOOLERCHICOS")
                    return "VISICOOLER CHICOS";
                if (exhibition == "FASTLINE")
                    return "FAST LINE";
                if (exhibition == "VENTACRUZADAGANCHOS")
                    return "VENTA CRUZADA - GANCHOS";
                if (exhibition == "VENTACRUZADADISPENSADOR")
                    return "VENTA CRUZADA - DISPENSADOR";
                if (exhibition == "VENTACRUZADASCOOLER")
                    return "VENTA CRUZADA - S/COOLER";
                if (exhibition == "MINICABECERAS")
                    return "MINI CABECERAS";
                if (exhibition == "MESADECARGA")
                    return "MESA DE CARGA";
                if (exhibition == "ISLA")
                    return "ISLA";
                if (exhibition == "COLUMNA")
                    return "COLUMNA";
                if (exhibition == "FILAUNICA")
                    return "FILA UNICA";
                if (exhibition == "FILAUNICAZONAFRONTAL")
                    return "FILA UNICA ZONA FRONTAL";
                if (exhibition == "BTL")
                    return "BTL";
                else
                    return '';
            }

            function processImporterResults(importerResults) {
                self.isBusy = false;
                self.isBusy2 = false;
                self.isBusy3 = false;
                self.isBusy4 = false;

                if (importerResults.length > 0) {
                    loadResults(importerResults);
                }
                if (anyError(importerResults)) {
                    self.error = true;
                }
                else
                    self.error = false;
            }

            function loadResults(results) {
                self.gridOptions.api.setRowData(results);
                self.gridOptions.api.refreshView();

                self.gridVisible = results.length > 0 ? true : false;
            }

            function anyError(importerResults) {
                var error = false;
                for (var i = 0; i < importerResults.length; i++) {
                    if (importerResults[i].severity === 'Error')
                        error = true;
                }

                return error;
            }

            ngDialog.open({
                template: 'batchLoadDialogExhibitions',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                controller: function () {
                    return self;
                },
                controllerAs: 'ct',
            });
        }

        self.openChat = function (pageId) {
            //busca la pagina y abre el dialog
            angular.forEach(self.promotion.pages, function (p, index) {
                if (p.id === pageId) {

                    self.indexOfPageOnChat = index;
                }

            });


            ngDialog.open({
                template: 'chatDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: $scope
            });
        }


        self.swapPositions = function () {
            var newScope = $scope.$new();
            newScope.promotionMediaPageId = self.selectedPage.id;
            newScope.itemFrom = 0;
            newScope.itemTo = 0;

            newScope.saveSwapping = function (itemFrom, itemTo) {
                if (itemFrom <= 0 || itemTo <= 0 || itemFrom == null || itemTo == null || itemFrom > self.selectedPage.totalPositions || itemTo > self.selectedPage.totalPositions) {
                    swal("Error", "Alguna de las posiciones es incorrecta", "error");
                }
                else {
                    promotionsService.items.swapPositions(newScope.promotionMediaPageId, itemFrom, itemTo)
                        .then(function (rv) {
                            if (rv == "OK") {
                                init();
                                swal("Cambiado!", "Se cambiaron correctamente las posiciones", "success");
                            }
                            else
                                swal("Error", rv, "error");

                            ngDialog.close();

                        }, function (status) {
                            if (status == 502) {
                                swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                            }
                            else {
                                swal("Error", "Hubo un error al borrar este item", "error");
                            }
                        });
                }
            }
            ngDialog.open({
                template: 'swapItemsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });

        }

        self.publishItem = function (itemId) {
            console.log('addItemToLayoutController::publishItem')
            var newScope = $scope.$new();
            newScope.promotionMediaPageId = self.selectedPage.id;
            newScope.positionTo = 0;
            newScope.saveSwapping = function (positionTo) {
                if (positionTo <= 0 || positionTo == null || positionTo > self.selectedPage.totalPositions) {
                    swal("Error", "La posicion destino es incorrecta", "error");
                }
                else {
                    promotionsService.items.publishItem(newScope.promotionMediaPageId, itemId, positionTo, self.layoutPreviewGrid[positionTo - 1] != null ? self.layoutPreviewGrid[positionTo - 1].isHighlighted : false)
                        .then(function (rv) {
                            if (rv == "OK") {
                                loadPromotion();
                                swal("Cambiado!", "Se cambiaron correctamente las posiciones", "success");
                            }
                            else
                                swal("Error", rv, "error");

                            ngDialog.close();

                        }, function (status) {
                            if (status == 502) {
                                swal("El proceso continua...", "El proceso de intercambio continua. Puede revisar el estado en unos minutos.")
                            }
                            else {
                                swal("Error", "Hubo un error al intercambiar estos items", "error");
                            }
                        });
                }
            }

            ngDialog.open({
                template: 'publishItemDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });

        }

        self.gotoEditOptionalItem = function (promotionItemType, promotionItemId) {
            self.setPageInLocalStorage(self.selectedPage.id);
            var newScope = $scope.$new();
            newScope.promotionMediaPageId = self.selectedPage.id;
            newScope.taskId = $stateParams.taskId;
            newScope.isSubstitute = promotionItemType == ART_SUPLENTE;
            newScope.isHighlighted = false;
            newScope.promotionId = $stateParams.promotionId;
            newScope.promotionItemId = promotionItemId;
            newScope.promotionItemType = promotionItemType;

            $state.go('promotions.editItem', { promotionMediaPageId: newScope.promotionMediaPageId, taskId: newScope.taskId, position: 0, isHighlighted: newScope.isHighlighted, isSubstitute: newScope.isSubstitute, promotionItemId: newScope.promotionItemId, promotionId: newScope.promotionId, promotionItemType: promotionItemType });
        }

        self.removeItem = function (itemId) {

            swal({
                title: 'Esta seguro que desea eliminar el item del layout?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.items.deletePromotionItem(itemId)
                            .then(function (isOk) {
                                swal('Item Eliminado!', 'El item ha sido removido con exito.', 'success');
                                //posicion del layout borrada
                                var position = 0;
                                self.selectedPage.items.forEach(function (item) { if (item.id == itemId) { position = item.position; } });
                                //cantidad de items a borrar
                                var toDeleteCount = 0;
                                self.selectedPage.items.forEach(function (item) { if (item.position == position) { toDeleteCount++; } });

                                while (toDeleteCount > 0) {
                                    self.selectedPage.items.forEach(function (item, index) { if (item.position == position) { self.selectedPage.items.splice(index, 1); toDeleteCount--; } });
                                }

                                loadPromotion();

                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al borrar este item", "error");
                                }
                            });
                    }
                });


        }
        self.removeItemOfList = function (item) {

            swal({
                title: 'Esta seguro que desea eliminar el item del layout?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        promotionsService.items.deletePromotionItem(item.id)
                            .then(function (isOk) {
                                swal('Item Eliminado!', 'El item ha sido removido con exito.', 'success');

                                self.selectedPage.items.splice(self.selectedPage.items.indexOf(item), 1);
                                self.variantsHaveLength = false;
                                self.selectedPage.items.forEach(function (i) { if (i.promotionItemType == 5) { self.variantsHaveLength = true; } });

                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al borrar este item", "error");
                                }
                            });
                    }
                });
        }

        self.categoriesCaptains = [];
        self.categoriesSecondaries = [];

        self.layouts = [];

        self.layout = {
            id: 0,
            companyId: 0,
            name: '',
            description: '',
            rows: 0,
            columns: 0,
            backgroundImageUrl: '',
            details: []
        };

        self.layoutPreviewGrid = [];

        self.publish = function () {
            if ($stateParams.taskId && (AllLoaded() || self.promotion.promotionType == 'Interna')) {

                //1.Busco la tarea
                var task = null;
                angular.forEach(self.promotion.tasks, function (t) {
                    if (t.id == $stateParams.taskId)
                        task = t;
                });

                var canPublishTask = false;
                var taskName = task.name.split("(")[0].trim();//evito comparar tareas repetidas las cuales llevan (#nroDeRepeticion)

                //2.Me fijo si puedo publicar esa tarea con mi perfil/permiso
                if (taskName == 'Validación final' && self.canpublishValidacionFinal)
                    canPublishTask = true;
                if ((taskName == '1° Subida de Pieza para revisión' || taskName == '2° Subida de Pieza para revisión' || taskName == '3° Subida de Pieza para revisión') && self.canpublishSubidaDePieza)
                    canPublishTask = true;
                if ((taskName == '1° Input para armado de Pieza' || taskName == '2° Input para armado de Pieza' || taskName == '3° Input para armado de Pieza') && self.canpublishInputArmadoDePieza)
                    canPublishTask = true;
                if (taskName == '2° Revisión Comercial' && self.canPublishSecondRevision)
                    canPublishTask = true;
                if ((taskName == 'Carga Inicial' || taskName == '1° Revisión Comercial') && self.canPublishCargaArticulos)
                    canPublishTask = true;


                //3.Publico la tarea
                if (canPublishTask) {
                    promotionsService.tasks.publishTask($stateParams.taskId, self.selectedPage.id)
                        .then(function (task) {
                            if (task.effectiveEndedDate && task.effectiveEndedDate != null)
                                swal('Tarea Publicada!', 'La tarea se ha publicado exitosamente', 'success');
                            else {
                                swal('Pagina Publicada!', 'La pagina se ha publicado exitosamente', 'success');
                            }

                            $state.go('promotions.myTasks');
                        });
                }
                else
                    swal('Error!', 'No tiene el perfil o los permisos para publicar esta tarea', 'error');

            } else {
                $timeout(function () {
                    swal("Error", "layout incompleto", "error");
                }, 100);
            }
        }

        function AllLoaded() {

            var publishedItemsCount = 0;

            angular.forEach(self.selectedPage.items, function (i) {
                if (i.promotionItemType == 0 || i.promotionItemType == 1 || i.promotionItemType == 4) //Destacado, Publicado, Reservado
                    publishedItemsCount++;
            });

            if (publishedItemsCount < GetLayuotLenghtAvailable(self.selectedPage.promotionMediaPageLayoutId))
                return false;
            else
                return true;
        }

        function GetLayuotLenghtAvailable(layoutId) {
            for (var i = 0; i < self.layouts.length; i++) {
                if (self.layouts[i].id == layoutId) {
                    self.layout = self.layouts[i];
                    break;
                }
            }
            var len = 0;
            for (var i = 0; i < self.layout.details.length; i++) {
                if (self.layout.details[i].position != null)
                    len++;
            }
            return len;
        }

        self.canPublish = function () {
            if ($stateParams.taskId && parseInt($stateParams.taskId) > 0)
                return true;

            return false;
        }

        self.uploadPageImage = function (file, errFiles) {

            if (file) {

                var url = ian.urlCombine(serverService.getApiUrl(), '/promotion/promotions/uploadPageImage/' + self.selectedPage.id);

                self.uploadingPageImage = true;

                Upload.upload({
                    url: url,
                    data: { file: file }
                }).then(function (response) {
                    $timeout(function () {
                        self.uploadingPageImage = false;
                        self.selectedPage.imageUrl = response.data;
                        swal('Pieza subida con exito', 'Se ha actualizado la imagen de pieza a publicar.', 'success');
                    });
                }, function (response) {
                    self.uploadingPageImage = false;
                    if (response.status > 0)
                        $scope.errorMsg = response.status + ': ' + response.data;
                });
            }
            else if (errFiles && errFiles[0]) {
                var file = errFiles[0];

                var allowedSize = file.$errorParam;
                var size = (file.size / 1024).toFixed(0);

                swal('Imagen no valida', ian.format('El archivo {0} pesa {1}KB y el peso máximo permitido es: {2}', file.name, size, allowedSize), 'error');
            }
        }

        self.uploadMultiplePageImage = function (files, errFiles) {
            console.log('uploadMultiplePageImage:: files %o', files)
            if (files.length > 0) {

                var imgSizeMax = files.filter(function (img) {
                    return img.size > 4194304;
                })

                var imgs = files.filter(function (img) {
                    var limit = img.name.indexOf('.');
                    var imgName = img.name.substring(0, limit)
                    return self.selectedPages.find(function (page) { return page.pageNumber.toString() === imgName })
                })
                
                var imgNamesArr = [];
                imgs.forEach(function (img) {
                    var limit = img.name.indexOf('.');
                    var imgName = img.name.substring(0, limit)
                    imgNamesArr.push(imgName);
                })

                var promises = [];

                if (files.length <= self.selectedPages.length) {
                    for (var cont = 0; cont < imgs.length; cont++) {

                        var page = self.selectedPages.find(function (page) { return page.pageNumber.toString() === imgNamesArr[cont] })

                        var url = ian.urlCombine(serverService.getApiUrl(), '/promotion/promotions/uploadPageImage/' + page.id);

                        self.uploadingPageImage = true;

                        promises.push(
                            Upload.upload({
                            url: url,
                            data: { file: imgs[cont] }
                            }));
                    }

                    if (promises.length === imgs.length) {
                        $q.all(promises)
                            .then(function (data) {
                                $timeout(function () {

                                    data.forEach(function (response, index) {
                                        self.uploadingPageImage = false;
                                        var fileName = response.data.substring(response.data.lastIndexOf('/') + 1);
                                        var pageNumber = fileName.split('-')[1];
                                        page = self.selectedPages.find(function (page) { return page.pageNumber.toString() === pageNumber });
                                        page.imageUrl = response.data;

                                        if (imgs.length === self.selectedPages.length && imgSizeMax.length === 0) {
                                            $timeout(function () {
                                                swal('Piezas subida con exito', 'Se han actualizado las imagenes de pieza a publicar.', 'success');
                                            });
                                        } else if (imgs.length === self.selectedPages.length && imgSizeMax.length > 0) {
                                            $timeout(function () {
                                                swal('Imagen no valida', ian.format('El archivo {0} pesa {1}KB y el peso máximo permitido es: {2}', imgSizeMax[0].name, imgSizeMax[0].size, 4194304), 'error');
                                            });
                                        } else {
                                            $timeout(function () {
                                                swal('Algunas piezas se han subido con exito', 'Existen paginas por asignacion de piezas', 'success');
                                            });
                                        }
                                    });
                                });
                            }, function (response) {
                                self.uploadingPageImage = false;
                                if (response.status > 0)
                                    $scope.errorMsg = response.status + ': ' + response.data;
                            });
                    }
                } else {
                    swal('Esta ingresando un numero de archivos correspondientes mayor al numero de paginas existentes.', 'Por favor intente nuevamente.', 'error');
                }
            } else if (errFiles && errFiles[0]) {
                var file = errFiles[0];

                var allowedSize = file.$errorParam;
                var size = (file.size / 1024).toFixed(0);

                swal('Imagen no valida', ian.format('El archivo {0} pesa {1}KB y el peso máximo permitido es: {2}', file.name, size, allowedSize), 'error');
            }
        }

        self.print = function () {
            self.showAllitems = false;
            if (self.canpublishInputArmadoDePieza)
                $window.print();
        }

        self.printAll = function () {
            console.log('printAll');
            self.showIndividualItems = false;

            self.selectPage(self.selectedPages[0])
            self.showAllitems = true;

            setTimeout(function () {
                if (self.canpublishInputArmadoDePieza)
                    $window.print();

                self.selectIndividualPage(self.selectedPages[0]);
            }, 2000)

        }

        self.isValidating = false;
        self.isValidated = false;

        var categories = [];

        self.positionInLayout = 0;

        self.editItemWhitoutPosition = function (promotionItemId, salesUnitOfMeasureId, isSubstitute) {
            if (promotionItemId && promotionItemId != 0 && !self.isActivationTask) {
                var itemType = isSubstitute ? ART_SUPLENTE : ART_SINUBICACION;
                $state.go('promotions.editItem', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: 0, isHighlighted: false, isSubstitute: isSubstitute, promotionItemId: promotionItemId, promotionId: $stateParams.promotionId, promotionItemType: itemType, salesUnitOfMeasureId: salesUnitOfMeasureId });
            }
        }

        self.loadItemToPosition = function (position, isHighlighted, promotionItemId, promotionMechanicId, salesUnitOfMeasureId, isReserved) {

            self.positionInLayout = position;

            if (promotionMechanicId && promotionMechanicId != 0) {//es mecanica(edicion normal)
                if (!self.isActivationTask) {//no es activacion
                    $state.go('promotions.editMechanic', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, isHighlighted: isHighlighted, isSubstitute: false, promotionItemId: promotionItemId, promotionMechanicId: promotionMechanicId, promotionId: $stateParams.promotionId });
                }
                else {//es activacion (edicion de codigo pangui)
                    $state.go('promotions.editMechanic', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, isHighlighted: isHighlighted, isSubstitute: false, promotionItemId: promotionItemId, promotionMechanicId: promotionMechanicId, promotionId: $stateParams.promotionId, isEditPanguiCode: true });
                }
            }
            else
                if (isReserved) {
                    //TODO: por ahora no hacer nada, luego si hay un item, agregar un comentario.
                }
                else {
                    if (promotionItemId && promotionItemId != 0 && !self.isActivationTask) {//activacion no puede editar item
                        $state.go('promotions.editItem', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, isHighlighted: isHighlighted, isSubstitute: false, promotionItemId: promotionItemId, promotionId: $stateParams.promotionId, promotionItemType: ART_PUBLICADO, salesUnitOfMeasureId: salesUnitOfMeasureId });
                    }
                    else if (!self.isActivationTask && !self.isLayoutForReview && !self.isLayoutCompare && !self.isLayoutImageUpload && !self.isFinalValidation) {
                        //Abro el Dialog para seleccionar Precio Producto o Mecánica
                        //TODO: si ya tengo seleccionado un Precio Producto y quiero cambiar a una mecánica tengo que tener la posibilidad de quitar lo que hay para que me abra este dialog
                        var newScope = $scope.$new();
                        newScope.promotionMediaPageId = self.selectedPage.id;
                        newScope.taskId = $stateParams.taskId;
                        newScope.position = position;
                        newScope.isHighlighted = isHighlighted;
                        newScope.promotionId = $stateParams.promotionId;
                        newScope.promotionItemId = promotionItemId;
                        newScope.promotionMechanicId = promotionMechanicId;
                        newScope.promotionItemType = ART_PUBLICADO;

                        newScope.goToEditItem = function () {//nuevo item
                            self.setPageInLocalStorage(self.selectedPage.id);
                            $state.go('promotions.editItem', { promotionMediaPageId: newScope.promotionMediaPageId, taskId: newScope.taskId, position: newScope.position, isHighlighted: newScope.isHighlighted, isSubstitute: false, promotionItemId: newScope.promotionItemId, promotionId: newScope.promotionId, promotionItemType: ART_PUBLICADO, salesUnitOfMeasureId: salesUnitOfMeasureId });
                            ngDialog.close();
                        }

                        newScope.goToEditMechanic = function () {//nueva mecanica
                            self.setPageInLocalStorage(self.selectedPage.id);
                            $state.go('promotions.editMechanic', { promotionMediaPageId: newScope.promotionMediaPageId, taskId: newScope.taskId, position: newScope.position, isHighlighted: newScope.isHighlighted, isSubstitute: false, promotionItemId: newScope.promotionItemId, promotionMechanicId: newScope.promotionMechanicId, promotionId: newScope.promotionId });
                            ngDialog.close();
                        }

                        //Agregar items reservados.
                        newScope.addReserved = function () {
                            self.setPageInLocalStorage(self.selectedPage.id);
                            var reservedScope = $scope.$new();

                            reservedScope.itemDescription = '';
                            reservedScope.itemChat = '';
                            reservedScope.promotionMediaPageId = newScope.promotionMediaPageId;
                            reservedScope.isHighlighted = newScope.isHighlighted;
                            reservedScope.position = newScope.position;


                            reservedScope.saveReserved = function (description) {
                                reservedScope.adding = true;
                                promotionsService.items.saveReservedItem(reservedScope.promotionMediaPageId, reservedScope.position, reservedScope.isHighlighted, description)
                                    .then(function (item) {
                                        if (item.id != 0)
                                            swal('Listo!', 'item agregado', 'success');
                                        else swal('Error', 'no se pudo agregar el item', 'error');

                                        loadPromotion();
                                        reservedScope.adding = false;
                                        ngDialog.close();
                                    },
                                    function () {
                                        reservedScope.adding = false;
                                        ngDialog.close();
                                    });
                            }

                            reservedScope.cancelReserved = function () {
                                ngDialog.close();
                            }

                            ngDialog.open({
                                template: 'loadReservedDialog',
                                className: 'ngdialog-theme-default ngdialog-theme-custom',
                                scope: reservedScope
                            });
                        }

                        ngDialog.open({
                            template: 'promotionItemTypeDialog',
                            className: 'ngdialog-theme-default ngdialog-theme-custom',
                            scope: newScope
                        });
                    }
                }
        }

        /**
         * Abre un dialog con un listado de promotionItems 
         * @param {any} position posicion del layout
         * @param {any} isMechanic true si es mecanica, false si es precio producto
         */
        self.openAlternativeItemsDialog = function (position, isMechanic) {
            var newScope = $scope.$new();

            newScope.position = position;
            newScope.isMechanic = isMechanic;

            newScope.filterItems = function (i) {
                return i.position == position && (i.promotionMechanicId && isMechanic || !i.promotionMechanicId && !isMechanic) && i.promotionItemType == 6;
            }

            newScope.edit = function (item) {
                if (item.itemId) {
                    $state.go('promotions.editItem', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, promotionItemId: item.id, promotionId: $stateParams.promotionId, promotionItemType: ART_ALTERNATIVO, salesUnitOfMeasureId: item.salesUnitOfMeasureId });
                }
                else if (item.promotionMechanicId) {
                    $state.go('promotions.editMechanic', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, promotionItemId: item.id, promotionMechanicId: item.promotionMechanicId, promotionId: $stateParams.promotionId, promotionItemType: ART_ALTERNATIVO });
                }
                ngDialog.close();
            }

            ngDialog.open({
                template: 'alternativeItemsDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        }

        /**
         * Dialog con opcion de agregar otro item a una posicion, ya sea relacionado o alternativo*/
        self.openAddItemDialog = function (position, promotionItemId, isHighlighted) {
            if (promotionItemId && !self.isActivationTask && !self.isLayoutForReview && !self.isLayoutCompare && !self.isLayoutImageUpload && !self.isFinalValidation) {
                var newScope = $scope.$new();
                newScope.position = position;
                newScope.promotionItemId = promotionItemId;

                newScope.goToAddItem = function () {
                    self.setPageInLocalStorage(self.selectedPage.id);
                    $state.go('promotions.editItem', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, isHighlighted: isHighlighted, promotionId: $stateParams.promotionId, promotionItemType: ART_ALTERNATIVO });
                    ngDialog.close();
                }

                newScope.goToAddMechanic = function () {
                    self.setPageInLocalStorage(self.selectedPage.id);
                    $state.go('promotions.editMechanic', { promotionMediaPageId: self.selectedPage.id, taskId: $stateParams.taskId, position: position, isHighlighted: isHighlighted, promotionId: $stateParams.promotionId, promotionItemType: ART_ALTERNATIVO });
                    ngDialog.close();
                }

                ngDialog.open({
                    template: 'addItemDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    scope: newScope
                });
            }
        }

        self.loadVariant = function (position, promotionItemId) {

            self.positionInLayout = position;

            if (promotionItemId && promotionItemId != 0 && !self.isActivationTask && !self.isLayoutForReview && !self.isLayoutCompare && !self.isLayoutImageUpload && !self.isFinalValidation) {
                var newScope = $scope.$new();

                newScope.saveItem = function () {

                    promotionsService.items.savePromotionVariantItem(self.selectedItemInModal.id, promotionItemId, self.selectedItemInModal.saleUnitOfMeasureId)
                        .then(function (variantItem) {//por ahora no esta siendo necesario que me devuelva este dto
                            loadPromotion();
                        });
                    self.itemLookupText = null;

                    ngDialog.close();
                }

                ngDialog.open({
                    template: 'loadVariantDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    scope: newScope
                });
            }
        }

           /**
         * /Agrega un item alternativo a una posicion del layout 
         * @param {any} position
         * @param {any} promotionItemId
         */
        //self.addItem = function (position, promotionItemId) {

        //}

        $scope.$watch('ct.itemLookupText', function (newVal, oldVal) {
            if (newVal) {

                if (newVal.id) {
                    self.selectedItemInModal = self.itemLookupText;
                }
                else if (newVal && newVal != oldVal && (newVal.length >= 4 || (oldVal.length && oldVal.length > 3)) && newVal != previousLookupText) {
                    previousLookupText = newVal;

                    self.itemsInLookup = [{ id: 0, code: '', name: 'Cargando Items con el texto ' + newVal + '...' }];
                    var lookupParameter = { searchTerm: newVal, categoryIds: self.selectedPage.secondaryCategoriesIds.concat(self.selectedPage.mainCategoryId) };
                    homeService.lookups.itemsLookup(lookupParameter)
                        .then(function (items) {
                            self.itemsInLookup = items;
                            if (items.length > 0)
                                self.lookupItemsNoResults = false;
                            else
                                self.lookupItemsNoResults = true;
                        });
                }
            }

        }, true);

        self.hideExcludedStoresPostion = function (item) {
            var rv = true;
            item.stores.forEach(function (s) {
                if (!s.isListed && !s.toList)
                    rv = false;
            });
            return rv;
        }

        self.canEnterPage = function (page) {
            var rv = false;

            if (page.publishedDate == null) { //Solo analizo la categoria si la pagina no fue publicada. si fue publicada no la muestro para entrar.
                angular.forEach(self.userCategories, function (catId) {
                    if (page.subCategoryIds.indexOf(parseInt(catId)) > -1) {
                        rv = true;
                    }
                });
            }

            //SuperUser or all categories
            if (self.userCategories.length == 0)
                rv = true;

            return rv;
        }

        self.checkSelectedPages = function (page) {
            var ret = false;
            if (self.selectedPages.length > 0) {
                self.selectedPages.forEach(function (item) {
                    if (item.id === page.id) {
                        ret = true
                    }
                })
            }

            return ret;
        }

        self.setPageInLocalStorage = function (id) {
            var pageId = { 'pageId': id }
            localStorage.setItem('idDeLaPagina', JSON.stringify(pageId));
        }

        function getPageId() {
            if (localStorage.getItem('idDeLaPagina') != null) {
                var objPageIdAux = localStorage.getItem('idDeLaPagina');
                var objPageId = JSON.parse(objPageIdAux);
                var pageId = objPageId.pageId;
                angular.forEach(self.promotion.pages, function (page) {
                    if (page.id == pageId) {
                        self.selectIndividualPage(page);
                    }
                });
            }
            localStorage.removeItem("idDeLaPagina");
        }

        self.selectIndividualPage = function (page) {
            self.showAllitems = false;
            self.showIndividualItems = true
            self.selectPage(page);

            if (!$scope.$$phase) {
                $scope.$apply();
            }
     }

        self.selectPage = function (page) {

            if (!self.isSuperUser && page != null && page.publishedDate != null && !self.isActivationTask) //Si la pagina fue publicada, le tiro un swal indicando que no puede ingresar, sino ingreso
                    swal('Pagina Publicada', 'Esta pagina ya fue publicada, por lo tanto no se puede acceder a ella.', 'error');
                else {
                    if (self.canEnterPage(page)) {
                        self.showExcludedStores = false;
                        if (self.selectedPage == null || page) {

                            var pageToSelect = null;
                            angular.forEach(self.promotion.pages, function (pp) {
                                if (pp.pageNumber == page.pageNumber)
                                    pageToSelect = pp;
                            });
                            self.selectedPage = pageToSelect;

                            if (self.selectedPage.items && self.selectedPage.items.length > 0) {
                                self.selectedPage.items.forEach(function (i) {
                                    if (i.promotionItemType == 5)
                                        self.variantsHaveLength = true;

                                    if (i.promotionItemType == 0 || i.promotionItemType == 1) {
                                        angular.forEach(i.stores, function (s) {
                                            if (!s.isListed && !s.toList)
                                                self.showExcludedStores = true;
                                        });
                                    }
                                });
                            }

                            if (self.selectedPage.promotionMediaPageLayoutId != 0) {

                                self.showPreview(self.selectedPage.promotionMediaPageLayoutId);
                            }
                        }
                    }
                }
            //}
        }

        self.showPreview = function (layoutId) {
            console.log('addItemToLayoutController::showPreview')

                for (i = 0; i < self.layouts.length; i++) {
                    if (self.layouts[i].id == layoutId) {
                        self.layout = self.layouts[i];

                        if (self.showAllitems && $('.showAllitems').is(':visible') || self.showIndividualItems && $('.showIndividualItems').is(':visible')) {
                            $rootScope.$broadcast('refreshLayoutTable', {});
                        break;
                    } else {
                        setTimeout(function () { $rootScope.$broadcast('refreshLayoutTable', {}); }, 1)
                    }

                    }
                }
        }


        function createPages() {
            for (var i = 0; i < self.promotion.numberOfPages; i++) {
                var exist = false;
                angular.forEach(self.promotion.pages, function (page) {
                    if (page.pageNumber == i + 1)
                        exist = true;
                });
                if (!exist) {
                    var page = {
                        promotionId: self.promotion.id,
                        promotionMediaPageLayoutId: 0,
                        pageNumber: i + 1,
                        promotionMediaPageType: i == 0 ? 1 : (i + 1 == self.promotion.numberOfPages ? 2 : 3),
                        mainCategoryId: 0,
                        secondaryCategoriesIds: [],
                        items: []
                    };

                    self.promotion.pages.splice(i, 0, page);
                }
            }
        }

        function loadPromotion() {
            console.log('addItemToLayoutController::loadPromotion')
            if ($stateParams.promotionId != '0') {

                promotionsService.promotions.getPromotionPagesItems($stateParams.promotionId)
                .then(function (promotion) {
                    if (promotion != null) {
                        self.promotion = promotion;

                        self.isLoading = false;

                        createPages();

                        selectWorkingPage();

                        self.promotion.pages.forEach(function (page, index) {
                            if (!self.checkSelectedPages(page)) {
                                    self.selectedPages.push(page)
                            }
                        })
                        getPageId();

                        if (self.selectedPage)
                            self.selectPage(self.selectedPage);


                        if (self.selectedPage != null && self.selectedPage.items.length > 0)
                            self.selectedPage.items.forEach(function (i) { if (i.promotionItemType == 5) { self.variantsHaveLength = true; } });
                    }

                    var task = null;
                    angular.forEach(self.promotion.tasks, function (t) {
                        if (t.id == $stateParams.taskId)
                            task = t;
                    });
                    var taskName = task.name.split("(")[0].trim();//evito comparar tareas repetidas las cuales llevan (#nroDeRepeticion)
                    if (taskName != 'Carga Inicial' && taskName != '1° Revisión Comercial') {
                        self.disableMassiveCharges = true;
                    }

                });
            }
        }

        function selectWorkingPage() {
            var pageId = self.selectedPage ? self.selectedPage.id : $stateParams.promotionMediaPageId;
            var currentPage = null;
            if (pageId && pageId > 0) {
                angular.forEach(self.promotion.pages, function (page) {
                    if (page.id == pageId) {
                        currentPage = page;
                    }
                });
            }

            if (currentPage)
                self.selectPage(currentPage);
        }

        self.checkIssueInPosition = function (position) {
            var rv = false;
            if (self.issues != null && self.issues.length > 0)
                self.issues.forEach(function (issue) {
                    if (issue.pageNumber == self.selectedPage.pageNumber && issue.position == position)
                        rv = true;
                });
            return rv;
        }

        self.checkIssueForSubstitute = function (promotionItemId) {
            var rv = false;
            if (self.issues != null && self.issues.length > 0)
                self.issues.forEach(function (issue) {
                    if (issue.itemId == promotionItemId)
                        rv = true;
                });
            return rv;
        }

     function init() {
         console.log('addItemToLayoutController::init')

         //por ahora se esta usando solo el getTaskName para identificar tarea
         self.isLayoutCompare = $stateParams.isPrintLayoutCompare == 'true' ? true : false;
         self.isLayoutImageUpload = $stateParams.isLayoutImageUpload == 'true' ? true : false;
         self.isLayoutForReview = $stateParams.isLayoutForReview == 'true' ? true : false;
         self.isFinalValidation = self.isLayoutCompare && self.isLayoutImageUpload ? true : false;

         promotionsService.tasks.getTaskName($stateParams.taskId)
             .then(function (taskName) {
                 switch (taskName) {
                     case "1° Input para armado de Pieza": self.isLayoutCompare = true;
                         break;
                     case " 2° Input para armado de Pieza": self.isLayoutCompare = true;
                         break;
                     case "3° Input para armado de Pieza": self.isLayoutCompare = true;
                         break;
                     case "1° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                         break;
                     case "2° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                         break;
                     case "3° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                         break;
                     case "2° Revisión Comercial": self.isLayoutForReview = true;
                         break;
                     case "Validación final": self.isFinalValidation = true;
                         break;
                     case "Activación": self.isActivationTask = true;
                         break;
                 }

             });

         promotionsService.tasks.getTaskIssues($stateParams.taskId)
            .then(function (issues) {
                self.issues = issues;
                if (self.issues.length > 0)
                    self.issueName = promotionsService.getIssueName(self.issues[0].type);
            });

         homeService.categories.getLayoutCategories()
            .then(function (response) {
                angular.forEach(response, function (category) {
                    categories.push({
                        categoryId: category.id,
                        displayName: category.name
                    });

                });

                self.categoriesCaptains = categories;
                self.categoriesSecondaries = categories;

                promotionsService.layouts.getLayouts(true)
                .then(function (layouts) {
                    self.layouts = layouts;
                    loadPromotion();
                });
            });
     }

     init();
 });