angular.module("prisma")
    .controller("reportsCtrl", function ($scope, $rootScope, $state, $stateParams, promotionsService, serverService, Upload, authService, $timeout, ngDialog, $filter, $window) {

        var self = this;

        self.isLoading = false;
        self.fromDate = null;
        self.toDate = null;
        self.promotionIds = "";
        self.promotionName = "";
        self.promotionCreationUsers = "";
        self.materialCodes = "";
        self.report = null;
        self.promotionType = 0;
        self.promotionMediaTypes = promotionsService.promotionMediaTypes;
        self.promotions = null;
        self.selectedPromotionMediaType = null;
        self.selectedIdsForPromotionReport = [];
        self.allPromotionsForReport = false;

        self.deleteAll = function () {
            self.fromDate = null;
            self.toDate = null;
            self.promotionIds = "";
            self.promotionName = "";
            self.promotionCreationUsers = "";
            self.materialCodes = "";
            self.report = null;
            self.promotionType = 0;
            self.promotions = null;
            self.selectedPromotionMediaType = null;
            self.allPromotionsForReport = false;
            self.selectedIdsForPromotionReport = [];
        }

        self.clearResult = function () {
            self.promotions = null;
            self.allPromotionsForReport = false;
            self.selectedIdsForPromotionReport = [];
        }

        self.run = function () {
            if (self.fromDate != null) {
                self.clearResult();
                self.isLoading = true;
                var selectedpromotionMediaTypeId = 0;
                if (self.selectedPromotionMediaType != null) {
                    selectedpromotionMediaTypeId = self.selectedPromotionMediaType.id;
                }
                var filters = {
                    ids: self.promotionIds,
                    name: self.promotionName,
                    creationUsers: self.promotionCreationUsers,
                    materialCodes: self.materialCodes,
                    promotionType: self.promotionType,
                    promotionMediaType: selectedpromotionMediaTypeId,
                    validFrom: self.fromDate,
                    validTo: self.toDate
                }
                promotionsService.promotions.getPromotionsByReportFilters(filters)
                    .then(function (promotions) {
                        self.promotions = promotions;
                        self.isLoading = false;
                        angular.forEach(self.promotions, function (p) {
                            p.isChecked = false;
                        });
                        console.log(self.promotions);
                    });
            }
            else {
                swal("Error", "Seleccione al menos la fecha 'Desde'", 'error');
            }
        }

        self.setPromotionForReport = function (promotion) {
            if (promotion.isChecked && !self.selectedIdsForPromotionReport.includes(promotion.id)) {
                self.selectedIdsForPromotionReport.push(promotion.id);
            }
            else if (!promotion.isChecked && self.selectedIdsForPromotionReport.includes(promotion.id)) {
                var index = self.selectedIdsForPromotionReport.indexOf(promotion.id);
                if (index > -1) {
                    self.selectedIdsForPromotionReport.splice(index, 1);
                }
            }
        }

        self.setAllPromotionsForReport = function () {
            var doSelect = undefined;
            if (self.allPromotionsForReport) {
                doSelect = true;
            }
            else {
                doSelect = false;
            }
                angular.forEach(self.promotions, function (promotion) {
                    promotion.isChecked = doSelect;
                    self.setPromotionForReport(promotion);
            });
        }

        self.generateReport = function () {
         
            if (self.selectedIdsForPromotionReport.length > 0 && self.report != null) {
                self.isLoading = true;
                promotionsService.reports.buildPromotionReport({ report: self.report, promotionIds: self.selectedIdsForPromotionReport })
                    .then(function (link) {
                        self.isLoading = false;
                        window.open(link);
                    });
            }
            else {
                swal("Error", "Debe seleccionar al menos un id del listado de promociones y un tipo de reporte.", "error");
            }   
        }
      
    });